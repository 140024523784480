import React from 'react';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import './my-contest-versus-picks-upcoming.scss';
import $ from 'jquery';
import Moment from 'react-moment';
import { messages } from "../messages";
import PlayerStatsPopup from "../player-stats-popup/player-stats-popup";
import beltImage from "../../assets/images/belt_render.jpg";

let picksBody = {
    contestId: 0,
    currentPage: 1,
    currentSize: 100,
    userContestId: 0
};

const getPicksOptions = {
    method: 'post',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
    },
    body: JSON.stringify(picksBody)
};

class MyContestVersusPicksUpcoming extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            picks: [],
            contestId: this.props.match.params.contestId,
            userContestId: this.props.match.params.userContestId,
            pickObj: {},
            icePlayerArray: [],
            maximumPoint: 0,
            numberOfEntries: 1,
            icePicks: 0,
            picksNo: 0,
            similarPicks: [],
            selectedContest: [],
            payData: [],
            picksArray: [],

        };
        this.fetchUpcomingPicksData = this.fetchUpcomingPicksData.bind(this);
        this.checkForSelection = this.checkForSelection.bind(this);
        this.addMax = this.addMax.bind(this);
        this.addIcePicks = this.addIcePicks.bind(this);
        this.inviteFriend = this.inviteFriend.bind(this);
        this.withdrawContest = this.withdrawContest.bind(this);
        this.fetchApplySimilarData = this.fetchApplySimilarData.bind(this);
        this.applySimilarPicks = this.applySimilarPicks.bind(this);
        this.selectSimilarPick = this.selectSimilarPick.bind(this);
        this.reEnterContest = this.reEnterContest.bind(this);
        this.pay = this.pay.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.playerStats = React.createRef();
    }


    /*get picks and format array*/
    fetchUpcomingPicksData(getPicksOptions) {
        $('.loader').addClass('show');
        let picks = [];

        fetch(process.env.REACT_APP_SERVER_URL + 'contests/availableProps', getPicksOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    const picksData = this.sortByIce(data['response']['data']);
                    picksData.forEach((data, index) => {
                        let contestProp = data['contestProp'];
                        let userPropPic = data['userPropPick'];
                        if (userPropPic !== null) {
                            picks.push({
                                contestId: contestProp['contestId'],
                                player1Points: contestProp['player1Points'],
                                player1: {
                                    firstName: contestProp['player1']['firstName'],
                                    height: contestProp['player1']['height'],
                                    id: contestProp['player1']['id'],
                                    image: contestProp['player1']['image'],
                                    lastName: contestProp['player1']['lastName'],
                                    leagueType: contestProp['player1']['leagueType'],
                                    playerId: contestProp['player1']['playerId'],
                                    positionAbbreviation: contestProp['player1']['positionAbbreviation'], // SG
                                    propParameters: contestProp['player1']['propParameters'],
                                    teamAbbr: contestProp['player1']['teamAbbr'],
                                    uniform: contestProp['player1']['uniform'],
                                    weight: contestProp['player1']['weight'],
                                },
                                player1TeamId: contestProp['player1TeamId'],
                                player1Advantage: contestProp['player1Advantage'],
                                player2Points: contestProp['player2Points'],
                                player2: {
                                    firstName: contestProp['player2']['firstName'],
                                    height: contestProp['player2']['height'],
                                    id: contestProp['player2']['id'],
                                    image: contestProp['player2']['image'],
                                    lastName: contestProp['player2']['lastName'],
                                    leagueType: contestProp['player2']['leagueType'],
                                    playerId: contestProp['player2']['playerId'],
                                    positionAbbreviation: contestProp['player2']['positionAbbreviation'], // SG
                                    propParameters: contestProp['player2']['propParameters'],
                                    teamAbbr: contestProp['player2']['teamAbbr'],
                                    uniform: contestProp['player2']['uniform'],
                                    weight: contestProp['player2']['weight'],
                                },
                                player2TeamId: contestProp['player2TeamId'],
                                player2Advantage: contestProp['player2Advantage'],
                                propId: contestProp['propId'],
                                propValue: contestProp['propValue'],
                                startDate: contestProp['startTime'],
                                startTime: contestProp['startTime'],
                                statEventId: contestProp['statEventId'],
                                team1Abbr: contestProp['team1Abbr'],//NYK
                                team2Abbr: contestProp['team2Abbr'],//HOU
                                team1Name: contestProp.player1.lastName,
                                team2Name: contestProp.player2.lastName,
                                event1Team1Name: contestProp['event1Team1Name'],
                                event1Team2Name: contestProp['event1Team2Name'],
                                event2Team1Name: contestProp['event2Team1Name'],
                                event2Team2Name: contestProp['event2Team2Name'],
                                team1locationType: contestProp['team1locationType'],
                                team2locationType: contestProp['team2locationType'],
                                underPoints: contestProp['underPoints'],
                                venueState: contestProp['venueState'],
                                selected: false,
                                minPropsToPick: contestProp['minPropsToPick'],
                                team1AbbrPlayer2: contestProp['team1AbbrPlayer2'],
                                team2AbbrPlayer2: contestProp['team2AbbrPlayer2'],
                                userPropPick: {
                                    entryCount: userPropPic.entryCount,
                                    isICE: userPropPic.isICE,
                                    isOver: userPropPic.isOver,
                                    propID: userPropPic.propID,
                                    userPoints: userPropPic.isOver ? contestProp['overPoints'] : contestProp.underPoints,
                                    icePrimary: userPropPic.icePrimary,
                                    propDisabled: contestProp.propDisabled,
                                    playerId: contestProp.player1.id
                                }
                            });
                            this.checkForSelection(contestProp, picksData[index].userPropPick, picksData[index]['contestProp'].propDisabled);
                        }
                    });

                    this.setState({ picks: picks, minimumPick: picks[0].minPropsToPick });
                    this.addMax();
                    this.addIcePicks();

                    $('.loader').removeClass('show');
                } else {
                    $('.loader').removeClass('show');
                }
            });

    }

    addMax() {
        let picksData = this.state.picks;
        let maximumPoint = this.state.maximumPoint;
        let primaryScore = {}, secondaryScore = {};
        picksData.forEach(data => {
            if (data.userPropPick.isICE && data.userPropPick.icePrimary) {
                primaryScore = data.userPropPick;
            } else if (data.userPropPick.isICE && !data.userPropPick.icePrimary) {
                secondaryScore = data.userPropPick;
            }
            if (!data.userPropPick.isICE && !data.userPropPick.propDisabled) {
                maximumPoint += data.userPropPick.userPoints;
            }

        });
        this.setState({ maximumPoint: maximumPoint });

        this.checkForDisable(primaryScore, secondaryScore);
    }

    checkForDisable(primaryScore, secondaryScore) {
        let count = 0;
        let picksData = this.state.picks;
        let maximumPoint = this.state.maximumPoint;
        picksData.forEach(data => {
            if (!data.userPropPick.isICE && data.userPropPick.propDisabled) {
                count++;
                if (count === 1) {
                    if (!primaryScore.propDisabled) {
                        maximumPoint += primaryScore.userPoints;
                    } else {
                        if (!secondaryScore.propDisabled) {
                            maximumPoint += secondaryScore.userPoints;
                            count++;
                        }
                    }
                } else if (count === 2) {
                    if (!secondaryScore.propDisabled) {
                        maximumPoint += secondaryScore.userPoints;
                    }
                }

            }
        });
        this.setState({ maximumPoint: maximumPoint });
    }

    addIcePicks() {
        let picksData = this.state.picks;
        let icePicks = this.state.icePicks;
        let picksNo = this.state.picksNo;

        picksData.forEach(data => {
            if (data.userPropPick.isICE) {
                icePicks++;
            } else {
                picksNo++;
            }
        });
        this.setState({ icePicks: icePicks, picksNo: picksNo });
    }

    sortByIce(data) {
        let arr = [];
        let primaryIce;
        let secondaryIce;
        for (let i = 0; i < data.length; i++) {
            if (data[i]['userPropPick'] == null) {
                arr.push(data[i]);
            } else {
                if (data[i]['userPropPick'].isICE) {
                    if (data[i]['userPropPick'].icePrimary) {
                        primaryIce = data[i];
                    } else {
                        secondaryIce = data[i];
                    }
                } else {
                    arr.push(data[i]);
                }
            }
        }
        primaryIce && arr.push(primaryIce);
        secondaryIce && arr.push(secondaryIce);
        return arr;
    }

    // to check the selections params id & userPropPick
    checkForSelection(picks, userPropPick, propDisabled) {
        let pickObj = this.state.pickObj;
        const id = picks.propId;
        if (userPropPick !== null) {
            pickObj[id] = {} || pickObj[id];
            pickObj[id].icePick = userPropPick.isICE;
            pickObj[id].icePrimary = userPropPick.icePrimary;
            pickObj[id].isOverSelected = userPropPick.isOver;
            pickObj[id].isUnderSelected = !userPropPick.isOver;
            pickObj[id].selected = true;
            pickObj[id].selectedPoints = userPropPick.userPoints;
            pickObj[id].propDisabled = propDisabled;
            pickObj[id].playerId = picks.player1.id;
            this.setState({ pickObj: pickObj });
        }
    }


    componentDidMount() {
        picksBody['contestId'] = this.props.match.params.contestId;
        picksBody['userContestId'] = this.props.match.params.userContestId;
        getPicksOptions['body'] = JSON.stringify(picksBody);
        this.fetchUpcomingPicksData(getPicksOptions);
    }

    /*Open Invite friend popup */
    openInviteFriendModal() {
        $('.invite-friend-popup').addClass('show');
    }

    /*Close Invite friend popup */
    closeInviteFriendModal() {
        $('.invite-friend-popup').removeClass('show');
    }

    inviteFriend() {
        $('.invite-friend-popup').find('.error').hide();
        const email = $('.friend-email').val();

        if (!validateEmail(email)) {
            $('.invite-friend-popup').find('.error').show();
            return
        }

        $('.loader').addClass('show');
        const inviteFriendBody = {
            inviteeEmail: email,
            contestId: this.state.contestId
        };

        const getInviteFriendOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
            },
            body: JSON.stringify(inviteFriendBody)
        };

        fetch(process.env.REACT_APP_SERVER_URL + 'invitee/user-invitee', getInviteFriendOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    messages('success', data['message']);
                    this.closeInviteFriendModal();
                } else {
                    messages('error', data['message']);
                }
                $('.loader').removeClass('show');
            });


    }

    openWithdrawContestPopup() {
        $('.withdraw-popup').addClass('show');
    }

    closeWithdrawContestPopup() {
        $('.withdraw-popup').removeClass('show');
    }

    withdrawContest() {
        $('.loader').addClass('show');

        const withdrawBody = {
            contestId: this.state.contestId,
            userContestId: this.state.userContestId
        };

        const getWithdrawOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
            },
            body: JSON.stringify(withdrawBody)
        };

        fetch(process.env.REACT_APP_SERVER_URL + 'contests/withdraw-contest', getWithdrawOptions)
            .then(response => response.json())
            .then(data => {
                messages('success', data['message']);
                this.props.history.push(process.env.REACT_APP_START_URL + 'my-contests/upcoming');
                $('.loader').removeClass('show');
            });
    }

    /*open Apply Similar popup and run function to get Similar Picks Data */
    openApplySimilarModal() {
        this.fetchApplySimilarData();
        $('.apply-similar-modal').addClass('show');
    }

    /*Close Apply Similar popup */
    closeApplySimilarModal() {
        $('.apply-similar-modal').removeClass('show');
    }

    fetchApplySimilarData() {
        $('.loader').addClass('show');
        const applySimilarBody = {
            contestId: this.state.contestId,
            userContestId: this.state.userContestId
        };

        const getApplySimilarOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
            },
            body: JSON.stringify(applySimilarBody)
        };

        fetch(process.env.REACT_APP_SERVER_URL + 'userContests/importPicks', getApplySimilarOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    let similarPicks = data['response']['data'];

                    similarPicks.forEach((contestData, index) => {
                        contestData['selected'] = false;
                    });

                    this.setState({ similarPicks: data['response']['data'] });
                } else {
                    messages('error', data['message']);
                }
                $('.loader').removeClass('show');
            });
    }

    applySimilarPicks() {
        const userContestId = $('input[name="similar-pick"]:checked').val();
        const contestId = $('input[name="similar-pick"]:checked').attr('rel');

        let selectedContest = [];
        this.state.similarPicks.map(data => {
            if (data.selected === true) {
                selectedContest.push({ id: contestId, userContestId: userContestId });
            }
        });

        if (selectedContest.length === 0) {
            messages('error', 'Please select at least 1 contest');
            return
        }
        $('.loader').addClass('show');

        const applySimilarBody = {
            adminContestsId: this.state.contestId,
            userContestId: this.state.userContestId,
            similarContest: selectedContest
        };

        const getApplySimilarOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
            },
            body: JSON.stringify(applySimilarBody)
        };

        fetch(process.env.REACT_APP_SERVER_URL + 'userContests/apply-user-picks-to-similar-contests', getApplySimilarOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    messages('success', data['message']);
                    this.closeApplySimilarModal();
                } else {
                    messages('error', data['message']);
                }
                $('.loader').removeClass('show');
            });
    }

    selectSimilarPick(event) {
        const target = event.target;
        const value = target.value;
        const checked = target.checked;
        let similarPicks = this.state.similarPicks;
        similarPicks.forEach((contestData) => {
            if (contestData['userContestId'].toString() === value.toString()) {
                contestData['selected'] = checked;
            }
        });
        this.setState({ similarPicks: similarPicks });
    }

    handleInputChange(event) {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        value = value.replace(/[^0-9]+/g, '');
        if ((value / 1) >= this.state.payData.maxEntriesPerUser) {
            value = this.state.payData.maxEntriesPerUser;
        }

        $('.number-of-entries').val(value);

        this.setState({
            [name]: value
        });
    }

    reEnterContest() {
        let payData = {
            numberOfPicks: this.state.picked,
            numberOfEntries: 1,
            entryFee: this.props.contest.entryFee,
            maxEntriesPerUser: this.props.contest.maxEntriesPerUser,
            maximumPoints: this.state.maximumPoint,
            edit: "false"
        };
        this.setState({ payData: payData });

        if (this.state.picked < this.state.minimumPick) {
            messages('error', 'Please select ' + (this.state.minimumPick - this.state.picked) + ' more props');

        } else if (this.state.icePick < 2) {
            if (this.state.icePick < 1) {
                messages('error', 'Please select ' + (2 - this.state.icePick) + ' ICE props');
            } else {
                messages('error', 'Please select ' + (2 - this.state.icePick) + ' more ICE props');
            }

        } else {
            $('.pay-modal').addClass('show');
            $('.number-of-entries').val('1');
        }
    }

    /*Validate picks and pay */
    pay() {
        $('.loader').addClass('show');
        if (this.state.numberOfEntries > this.state.payData.maxEntriesPerUser) {
            messages('error', 'Maximum entries allowed for this contest are ' + this.maxEntriesPerUser);
            $('.loader').removeClass('show');
        } else if (this.state.numberOfEntries === 0 || this.state.numberOfEntries === null) {
            messages('error', 'Please provide at least 1 entry ' + this.maxEntriesPerUser);
            $('.loader').removeClass('show');
        } else {
            this.checkForPicksObj();

            let requestPaymentObject = {
                adminContestsId: this.state.contestId,
                entryCount: this.state.numberOfEntries,
                propPickList: this.state.picksArray,
                maxPoints: this.state.maximumPoints,
                userContestId: this.state.userContestId
            };
            let state = 1;

            const getPaymentOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
                },
                body: JSON.stringify(requestPaymentObject)
            };

            fetch(process.env.REACT_APP_SERVER_URL + 'contests/enter/' + state, getPaymentOptions)
                .then(response => response.json())
                .then(data => {
                    if (data['success']) {
                        try {
                            window.contestEntryScript(document, "script")
                        } catch (error) {
                            console.error(error);
                        }
                        messages('success', data['message']);
                        this.props.history.push(process.env.REACT_APP_START_URL + 'my-contests/upcoming');
                    } else {
                        messages('error', data['message']);
                        $('.loader').removeClass('show');
                    }
                    $('.loader').removeClass('show');
                });

            // const getWalletBalanceOptions = {
            //     method: 'GET',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Access-Control-Allow-Origin': '*',
            //         'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
            //     }
            // };

            // fetch(process.env.REACT_APP_SERVER_URL + 'users/get-wallet-balance', getWalletBalanceOptions)
            //     .then(response => response.json())
            //     .then(data => {
            //         if (data['success']) {
            //             if (data['response']['walletBalance'] < this.state.payData.entryFee) {
            //                 $('.loader').removeClass('show');
            //                 messages('error', 'You have insufficient balance to withdraw your balance');
            //             } else {
            //                 const getPaymentOptions = {
            //                     method: 'post',
            //                     headers: {
            //                         'Content-Type': 'application/json',
            //                         'Access-Control-Allow-Origin': '*',
            //                         'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
            //                     },
            //                     body: JSON.stringify(requestPaymentObject)
            //                 };

            //                 fetch(process.env.REACT_APP_SERVER_URL + 'contests/enter/' + state, getPaymentOptions)
            //                     .then(response => response.json())
            //                     .then(data => {
            //                         if (data['success']) {
            //                             try {
            //                                 window.contestEntryScript(document,"script")
            //                             } catch (error) {
            //                                 console.error(error);
            //                             }
            //                             messages('success', data['message']);
            //                             this.props.history.push(process.env.REACT_APP_START_URL + 'my-contests/upcoming');
            //                         } else {
            //                             messages('error', data['message']);
            //                             $('.loader').removeClass('show');
            //                         }
            //                         $('.loader').removeClass('show');
            //                     });
            //             }
            //         } else {
            //             messages('error', data['message']);
            //             $('.loader').removeClass('show');
            //         }
            //     });
        }
    }

    /*to get picks array */
    checkForPicksObj() {
        let pickObj = this.state.pickObj;
        let picksArray = this.state.picksArray;
        for (let i in pickObj) {
            if (pickObj.hasOwnProperty(i)) {
                if (pickObj[i].selected) {
                    picksArray.push({
                        adminContestPropsId: i,
                        ice: pickObj[i].icePick,
                        over: pickObj[i].isOverSelected,
                        icePrimary: pickObj[i].icePrimary,
                        playerId: pickObj[i].playerId
                    })
                }
            }
        }
        this.setState({ picksArray: picksArray });
    }

    /*Close Pay popup */
    closePayModal() {
        $('.pay-modal').removeClass('show');
    }

    triggerPlayerStatsPopup(player) {
        this.playerStats.current.openPlayerStatsPopup(player);
    }

    getMatchupStylingHomePlayer1(locationType, leagueType, pick) {
        let uniform = pick.player1.uniform !== null ? parseInt(pick.player1.uniform) : null
        if(locationType !== 'home') {
            return ''
        }
        else {
            if(uniform !== null && uniform !== 0) {
                return <span>#{pick.player1.uniform} {pick.player1.positionAbbreviation} | {pick.event1Team1Name} vs. {pick.event1Team2Name}</span>
            }
            else if(uniform === null) {
                return <span>{pick.player1.positionAbbreviation} | {pick.event1Team1Name} vs. {pick.event1Team2Name}</span>
            }
            else if(uniform === 0) {
                return <span><img src={beltImage} style={{width: '30px', marginRight: '5px', marginTop: '5px'}}/> {pick.player1.positionAbbreviation} | {pick.event1Team1Name} vs. {pick.event1Team2Name}</span>
            }
        }

    }
    getMatchupStylingAwayPlayer1(locationType, leagueType, pick) {
        let uniform = pick.player1.uniform !== null ? parseInt(pick.player1.uniform) : null
        if(locationType !== 'away') {
            return ''
        }
        else {
            if(uniform !== null && uniform !== 0) {
                return <span>#{pick.player1.uniform} {pick.player1.positionAbbreviation} | {pick.event1Team1Name} vs. {pick.event1Team2Name}</span>
            }
            else if(uniform === null) {
                return <span>{pick.player1.positionAbbreviation} | {pick.event1Team1Name} vs. {pick.event1Team2Name}</span>
            }
            else if(uniform === 0) {
                return <span><img src={beltImage} style={{width: '30px', marginRight: '5px', marginTop: '5px'}}/> {pick.player1.positionAbbreviation} | {pick.event1Team1Name} vs. {pick.event1Team2Name}</span>
            }
        }
    }

    getMatchupStylingHomePlayer2(locationType, leagueType, pick) {
        let uniform = pick.player2.uniform !== null ? parseInt(pick.player2.uniform) : null
        if(locationType !== 'home') {
            return ''
        }
        else {
            if(uniform !== null && uniform !== 0) {
                console.log("1");
                return <span>#{pick.player2.uniform} {pick.player2.positionAbbreviation} | {pick.event2Team1Name} vs. {pick.event2Team2Name}</span>
            }
            else if(uniform === null) {
                console.log("2");
                return <span>{pick.player2.positionAbbreviation} | {pick.event2Team1Name} vs. {pick.event2Team2Name}</span>
            }
            else if(uniform === 0) {
                console.log("3");
                return <span><img src={beltImage} style={{width: '30px', marginRight: '5px', marginTop: '5px'}}/> {pick.player2.positionAbbreviation} | {pick.event2Team1Name} vs. {pick.event2Team2Name}</span>
            }
        }

    }

    getMatchupStylingAwayPlayer2(locationType, leagueType, pick) {
        let uniform = pick.player2.uniform !== null ? parseInt(pick.player2.uniform) : null
        if(locationType !== 'away') {
            return ''
        }
        else {
            if(uniform !== null && uniform !== 0) {
                console.log("1");
                return <span>#{pick.player2.uniform} {pick.player2.positionAbbreviation} | {pick.event2Team1Name} vs. {pick.event2Team2Name}</span>
            }
            else if(uniform === null) {
                console.log("2");
                return <span>{pick.player2.positionAbbreviation} | {pick.event2Team1Name} vs. {pick.event2Team2Name}</span>
            }
            else if(uniform === 0) {
                console.log("3");
                return <span><img src={beltImage} style={{width: '30px', marginRight: '5px', marginTop: '5px'}}/> {pick.player2.positionAbbreviation} | {pick.event2Team1Name} vs. {pick.event2Team2Name}</span>
            }
        }
    }

    render() {
        console.log(this.state.picks)
        return (
            <div className="tab-container my-contest-versus-upcoming active" rel="making-picks">
                <div className="actions clear-margin">
                    <Link to={process.env.REACT_APP_START_URL + "contest-lobby/" + this.state.contestId + "/" + this.state.userContestId} className="edit-picks"
                        title="Edit Picks">Edit Picks</Link>
                    <button onClick={() => this.reEnterContest()} className="re-enter-contest" title="Re-Enter Contest">Re-Enter Contest</button>
                    <button onClick={() => this.openApplySimilarModal()} className="apply-similar" title="Apply Similar">Apply Similar</button>
                </div>
                <div className="actions">
                    <button onClick={this.openWithdrawContestPopup} className="withdraw-contest" title="Withdraw Contest">Withdraw Contest</button>
                    <button onClick={() => this.openInviteFriendModal()} className="invite-friends" title="Invite Friends">Invite Friends</button>
                </div>
                <div className="upcoming-picks-listing">
                    <div className="head">
                        <span>Player 1</span>
                        <span>VS</span>
                        <span>Player 2</span>
                    </div>
                    <ul className="listing">
                        {this.state.picks.length ? this.state.picks.map(pick => (
                            <li key={pick.propId} >
                                {pick.player1.leagueType !== "UFC" ?
                                    <div className="date">
                                        <h4><Moment utc local format="hh:mm a">{pick.startTime}</Moment>, <Moment utc local format="ddd, MMM Do, YYYY">{pick.startDate}</Moment></h4>
                                    </div>
                                    :
                                    <div className="date">
                                        <h4> <Moment utc local format="ddd, MMM Do, YYYY">{pick.startDate}</Moment></h4>
                                    </div> }
                                    <div className="player">
                                    <img onClick={() => this.triggerPlayerStatsPopup(pick.player1)} src={pick.player1.image} alt={pick.player1.firstName} />
                                    <h3 onClick={() => this.triggerPlayerStatsPopup(pick.player1)}>
                                        {pick.player1.firstName} {pick.player1.lastName}
                                        {pick.team1locationType === 'home' && pick.player1.leagueType !== "UFC"
                                            ? (<span>{pick.player1.positionAbbreviation}-{pick.player1.teamAbbr}| {pick.team2Abbr} @ {pick.team1Abbr}</span>)
                                            : this.getMatchupStylingHomePlayer1(pick.team1locationType, pick.player1.leagueType, pick)
                                        }
                                        {pick.team1locationType === 'away' && pick.player1.leagueType !== "UFC"
                                            ? (<span>{pick.player1.positionAbbreviation}-{pick.player1.teamAbbr}| {pick.team1Abbr} @ {pick.team2Abbr}</span>)
                                            : this.getMatchupStylingAwayPlayer1(pick.team1locationType, pick.player1.leagueType, pick)
                                        }
                                    </h3>
                                    <h4>
                                        <span>Total | </span>
                                        {pick.player1.propParameters.length ? pick.player1.propParameters.map(prop => (
                                            <span className="prop" key={prop}>({prop})</span>
                                        )) : ''}
                                        <span> +{pick.player1Advantage}</span>
                                    </h4>
                                    {pick.userPropPick.isOver ?
                                        <span className="points">
                                            {pick.player1Points} PTS
                                        </span>
                                        :
                                        ''
                                    }
                                </div>
                                <div className="vs">
                                    <span>VS</span>
                                </div>
                                <div className="player">
                                    <img onClick={() => this.triggerPlayerStatsPopup(pick.player2)} src={pick.player2.image} alt={pick.player2.firstName} />
                                    <h3 onClick={() => this.triggerPlayerStatsPopup(pick.player2)}>
                                        {pick.player2.firstName} {pick.player2.lastName}
                                        {pick.team1locationTypePlayer2 === 'home' && pick.player2.leagueType !== "UFC"
                                            ? (<span>{pick.player2.positionAbbreviation}-{pick.player2.teamAbbr}| {pick.team2AbbrPlayer2} @ {pick.team1AbbrPlayer2}</span>)
                                            : this.getMatchupStylingHomePlayer2(pick.team2locationType, pick.player2.leagueType, pick, pick.player2.number)
                                        }
                                        {pick.team1locationTypePlayer2 === 'away' && pick.player2.leagueType !== "UFC"
                                            ? (<span>{pick.player2.positionAbbreviation}-{pick.player2.teamAbbr}| {pick.team1AbbrPlayer2} @ {pick.team2AbbrPlayer2}</span>)
                                            : this.getMatchupStylingAwayPlayer2(pick.team2locationType, pick.player2.leagueType, pick, pick.player2.number)
                                        }
                                    </h3>
                                    <h4>
                                        <span>Total | </span>
                                        {pick.player2.propParameters.length ? pick.player2.propParameters.map(prop => (
                                            (<span className="prop" key={prop}>({prop})</span>)
                                        )) : ''}
                                    </h4>
                                    {!pick.userPropPick.isOver ?
                                        <span className="points">
                                            {pick.player2Points} PTS
                                        </span>
                                        :
                                        ''
                                    }

                                </div>

                                {pick.userPropPick.isICE ?
                                    <div className="ice-pick">
                                        {pick.userPropPick.icePrimary ?
                                            <span>*ICE PICK 1</span> :
                                            <span>*ICE PICK 2</span>
                                        }
                                    </div>
                                    : ''
                                }
                            </li>
                        )) : ''}
                    </ul>
                </div>
                <div className="invite-friend-popup popup">
                    <div className="inner">
                        <button onClick={this.closeInviteFriendModal} className="close-invite-friend-modal close" title="Close"></button>
                        <h2>Invite Friend</h2>
                        <div>
                            <input className="friend-email" type="email" placeholder="Email" />
                            <button onClick={this.inviteFriend} title="Submit">Submit</button>
                            <span className="error">Valid Email Required</span>
                        </div>
                    </div>
                </div>
                <div className="withdraw-popup popup">
                    <div className="inner">
                        <h2>Are you sure you want to withdraw contest?</h2>
                        <div>
                            <button onClick={this.closeWithdrawContestPopup} title="Close">Close</button>
                            <button onClick={this.withdrawContest} title="Withdraw">Withdraw</button>
                        </div>
                    </div>
                </div>
                <div className="apply-similar-modal popup">
                    <div className="inner">
                        <button onClick={this.closeApplySimilarModal} className="close-import-picks-modal close" title="Close"></button>
                        <h2>Similar Upcoming Contests</h2>
                        <div>
                            <ul>
                                {this.state.similarPicks.length ? this.state.similarPicks.map(importPick => (
                                    <li key={importPick.userContestId}>
                                        <input onChange={this.selectSimilarPick} type="checkbox" name="similar-pick" rel={importPick.contestId} value={importPick.userContestId} />
                                        <div>
                                            <img src={importPick.gameImage} alt={importPick.name} />
                                            <h4>{importPick.name}</h4>
                                            <h5>Date <Moment utc local format="ddd, MMM Do, YYYY">{importPick.startDate}</Moment> | <Moment utc local
                                                format="hh:mm a">{importPick.startTime}</Moment>
                                            </h5>
                                            <span>Entry FeesL ${importPick.entryFee}.00</span>
                                        </div>
                                    </li>
                                )) : <p>No Contest Found</p>}
                            </ul>
                            <button onClick={this.applySimilarPicks} className="apply-picks" title="Apply Now">Apply Now</button>
                        </div>
                    </div>
                </div>
                <div className="pay-modal popup">
                    <div className="inner">
                        <button onClick={this.closePayModal} className="close-pay-modal close" title="Close"></button>
                        <h2>Enter Contest</h2>
                        <div>
                            <div>
                                <label>No. of Entries</label>
                                <input onChange={this.handleInputChange} name="numberOfEntries" className="number-of-entries"
                                    readOnly={this.state.payData.maxEntriesPerUser === 1 ? true : false} step="1" type="number" min="1"
                                    max={this.state.payData.maxEntriesPerUser} />
                            </div>
                            <div>
                                <label>Entry Fee</label>
                                <span>{formatNumber(this.state.payData.entryFee)}</span>
                            </div>
                            <div>
                                <label>Total Payments</label>
                                <span>{formatNumber(this.state.payData.entryFee * this.state.numberOfEntries)}</span>
                            </div>
                        </div>
                        <button onClick={this.pay} className="pay-now" title="Pay Now">Pay Now</button>
                    </div>
                </div>
                <PlayerStatsPopup ref={this.playerStats} />
            </div>
        );
    }
}

function formatNumber(num) {
    return '$' + (num * 1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function validateEmail($email) {
    var emailReg = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return emailReg.test($email);
}

export default withRouter(MyContestVersusPicksUpcoming);
