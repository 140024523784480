import React from 'react';
import {Link} from "react-router-dom";
import {withRouter} from "react-router";
import './my-contest-versus-head-to-head.scss';
import LoggedInHeader from '../Logged-in-header/Logged-in-header';
import $ from 'jquery';

import {isAuthenticated} from "../isAuthenticated";
import Footer from "../footer/footer";
import {messages} from "../messages";
import PlayerStatsPopup from "../player-stats-popup/player-stats-popup";

import Moment from "react-moment";
import beltImage from "../../assets/images/belt_render.jpg";


isAuthenticated('login', '/contest-lobby');


const requestHeadToHeadOptions = {
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
    },
};

class MyContestVersusHeadToHead extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view: this.props.match.params.view,
            contestId: this.props.match.params.contestId,
            userContestId: this.props.match.params.userContestId,
            opponentsId: this.props.match.params.opponentsId,
            opponentUserContestId: this.props.match.params.opponentUserContestId,
            H2Hdata: [],
            oppenentArray: [],
            picksData: [],
            pickArray: [],
            name: window.localStorage.getItem('HeadtoHeadData') ? JSON.parse(window.localStorage.getItem('HeadtoHeadData'))['name'] : false,
            rank: window.localStorage.getItem('HeadtoHeadData') ? JSON.parse(window.localStorage.getItem('HeadtoHeadData'))['rank'] : false,
            myPoints: 0,
            opponentPoints: 0,
        };

        this.fetchHeadToHeadData = this.fetchHeadToHeadData.bind(this);
        this.playerStats = React.createRef();
    }


    fetchHeadToHeadData(requestHeadToHeadOptions) {
        $('.loader').addClass('show');
        fetch(process.env.REACT_APP_SERVER_URL + 'contests/get-head-to-head-view-user-picks?contestid=' + this.state.contestId + '&opponentid=' + this.state.opponentsId + '&opponentUserContestId=' + this.state.opponentUserContestId + '&userContestId=' + this.state.userContestId, requestHeadToHeadOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    this.parseH2HData(data);
                    this.checkForTotalMyPoints();
                    $('.loader').removeClass('show');
                } else {
                    messages('error', data['message']);
                    $('.loader').removeClass('show');
                }
            });
    }


    componentDidMount() {
        this.fetchHeadToHeadData(requestHeadToHeadOptions);
    }

    parseH2HData(data) {
        let H2Hdata = [];
        let oppenentArray = [];
        let hth = this.checkProps(data['response']['myPropPicks']);
        let opponentHth = this.checkProps(data['response']['opponentsPropPicks']);
        let pushedArray = [];
        for (let i = 0; i < hth.length; i++) {
            H2Hdata.push(hth[i]);
            H2Hdata[i].oppenent = [];
        }
        for (let j = 0; j < opponentHth.length; j++) {
            for (let k = 0; k < hth.length; k++) {
                if (opponentHth[j].propId === H2Hdata[k].propId) {
                    H2Hdata[k].oppenent.push(opponentHth[j]);
                    pushedArray.push(hth[k].propId)
                }
            }
        }
        for (let i = 0; i < opponentHth.length; i++) {
            if (pushedArray.indexOf(opponentHth[i].propId) === -1) {
                oppenentArray.push(opponentHth[i]);
            }
        }
        this.setState({H2Hdata: H2Hdata, oppenentArray: oppenentArray});

    }

    checkProps(pickData) {
        let picksData = pickData;
        let pickArray = [];
        let primaryScore = {}, secondaryScore = {};
        picksData.forEach(data => {
            if (data.isICE && data.icePrimary) {
                primaryScore = data;
            } else if (data.isICE && !data.icePrimary) {
                secondaryScore = data;
            }
            if (!data.isICE && !data.propDisabled) {
                pickArray.push(data);
            }

        });
        this.setState({picksData: picksData, pickArray: pickArray});
        return this.checkForDisable(primaryScore, secondaryScore);
    }

    checkForDisable(primaryScore, secondaryScore) {
        let count = 0;
        let pickArray = this.state.pickArray;
        this.state.picksData.forEach(data => {
            if (!data.isICE && data.propDisabled) {
                count++;
                if (count === 1) {
                    if (!primaryScore.propDisabled) {
                        pickArray.push(primaryScore);
                    } else {
                        if (!secondaryScore.propDisabled) {
                            pickArray.push(secondaryScore);
                            count++;
                        }
                    }
                } else if (count === 2) {
                    if (!secondaryScore.propDisabled) {
                        pickArray.push(secondaryScore);
                    }
                }

            }
        });
        return pickArray;
    }

    teamAbr(data) {
        let str = '';
        const team1Score = data['team1Score'] ? data.team1Score : 0;
        const team2Score = data['team2Score'] ? data.team2Score : 0;
        if (data.team1locationType === 'home') {
            str = data.team2Abbr + ' ' + team2Score + ' - ' + data.team1Abbr + ' ' + team1Score;
        } else if (data.team1locationType === 'away') {
            str = data.team1Abbr + ' ' + team1Score + ' - ' + data.team2Abbr + ' ' + team2Score;
        }
        return str;
    }

    numSuffix(n) {
        const suffix = ["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th";
        return n + suffix;
    }

    checkForTotalMyPoints() {
        let myPoints = 0;
        let opponentPoints = 0;

        setTimeout(function () {
            $('.my-picks span.green').each(function () {
                myPoints += ($(this).attr('rel') / 1);
            });

            $('.opponents span.green').each(function () {
                opponentPoints += ($(this).attr('rel') / 1);
            });
            this.setState({myPoints: myPoints, opponentPoints: opponentPoints});
        }.bind(this), 1000);

    }

    propsSort(props, player) {
        let propString = [];
        if (player === 'player2') {
            for (let i = 0; i < props.length; i++) {
                if (!props[i].belongsToPlayer1) {
                    propString.push(props[i].abbreviation);
                }
            }
        } else if (player === 'player1') {
            for (let i = 0; i < props.length; i++) {
                if (props[i].belongsToPlayer1) {
                    propString.push(props[i].abbreviation);
                }
            }
        }
        return propString.join('+');
    }

    triggerPlayerStatsPopup(player) {
        this.playerStats.current.openPlayerStatsPopup(player);
    }

    getMatchupStylingHomePlayer1(locationType, leagueType, pick) {
        console.log(pick);
        let uniform = pick.player1.uniform !== null ? parseInt(pick.player1.uniform) : null
        if(locationType !== 'home') {
            return ''
        }
        else {
            if(uniform !== null && uniform !== 0) {
                return <span>#{pick.player1.uniform} | {pick.event1Team1Name} vs. {pick.event1Team2Name}</span>
            }
            else if(uniform === null) {
                return <span>{pick.event1Team1Name} vs. {pick.event1Team2Name}</span>
            }
            else if(uniform === 0) {
                return <span><img src={beltImage} style={{width: '30px', marginRight: '5px', marginTop: '5px'}}/>{pick.event1Team1Name} vs. {pick.event1Team2Name}</span>
            }
        }
    }

    getMatchupStylingAwayPlayer1(locationType, leagueType, pick) {
        let uniform = pick.player1.uniform !== null ? parseInt(pick.player1.uniform) : null
        if(locationType !== 'away') {
            return ''
        }
        else {
            if(uniform !== null && uniform !== 0) {
                return <span>#{pick.player1.uniform} | {pick.event1Team1Name} vs. {pick.event1Team2Name}</span>
            }
            else if(uniform === null) {
                return <span>{pick.event1Team1Name} vs. {pick.event1Team2Name}</span>
            }
            else if(uniform === 0) {
                return <span><img src={beltImage} style={{width: '30px', marginRight: '5px', marginTop: '5px'}}/> {pick.event1Team1Name} vs. {pick.event1Team2Name}</span>
            }
        }
    }

    getMatchupStylingHomePlayer2(locationType, leagueType, pick) {
        let uniform = pick.player2.uniform !== null ? parseInt(pick.player2.uniform) : null
        if(locationType !== 'home') {
            return ''
        }
        else {
            if(uniform !== null && uniform !== 0) {
                console.log("1");
                return <span>#{pick.player2.uniform} | {pick.event2Team1Name} vs. {pick.event2Team2Name}</span>
            }
            else if(uniform === null) {
                console.log("2");
                return <span> {pick.event2Team1Name} vs. {pick.event2Team2Name}</span>
            }
            else if(uniform === 0) {
                console.log("3");
                return <span><img src={beltImage} style={{width: '30px', marginRight: '5px', marginTop: '5px'}}/>{pick.event2Team1Name} vs. {pick.event2Team2Name}</span>
            }
        }

    }

    getMatchupStylingAwayPlayer2(locationType, leagueType, pick) {
        let uniform = pick.player2.uniform !== null ? parseInt(pick.player2.uniform) : null
        if(locationType !== 'away') {
            return ''
        }
        else {
            if(uniform !== null && uniform !== 0) {
                return <span>#{pick.player2.uniform} | {pick.event2Team1Name} vs. {pick.event2Team2Name}</span>
            }
            else if(uniform === null) {
                return <span>{pick.event2Team1Name} vs. {pick.event2Team2Name}</span>
            }
            else if(uniform === 0) {
                return <span><img src={beltImage} style={{width: '30px', marginRight: '5px', marginTop: '5px'}}/> {pick.event2Team1Name} vs. {pick.event2Team2Name}</span>
            }
        }
    }

    render() {
        return (
            <main className="my-contest-head-to-head-page versus">
                <LoggedInHeader/>
                <div className="inner">
                    <Link className="back-btn"
                          to={process.env.REACT_APP_START_URL + "my-contests/" + this.state.view + "/" + this.state.contestId + "/" + this.state.userContestId}
                          title="Back to Results">Back to Results</Link>
                    <h1>HEAD TO HEAD - with {this.state.name} (Rank {this.state.rank})</h1>
                    <div className="head-to-head-listing">
                        <div className="head">
                            <span className="player">Player1</span>
                            <span className="vs">VS</span>
                            <span className="player">player2</span>
                            <span className="my-picks">My Picks</span>
                            <span className="opponents">Opponent's</span>
                        </div>
                        <ul>
                            {this.state.H2Hdata.length ? this.state.H2Hdata.map(h2hd => (
                                <li key={h2hd.propId}>
                                    <div className="player" onClick={() => this.triggerPlayerStatsPopup(h2hd.player1)}>
                                        {
                                            h2hd.player1.leagueType === 'MLB' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && h2hd.inningEvent <= 9 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} INN | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'NFL' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && h2hd.inningEvent <= 4 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} QTR | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'NBA' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && h2hd.inningEvent <= 4 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} QTR | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'GOLF' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.numSuffix(h2hd.golfRound)} RND</span> : ''}
                                                    {!h2hd.event1Complete && h2hd.inningEvent !== 0 && h2hd.inningEvent != null ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} HOLE | {this.numSuffix(h2hd.golfRound)} RND</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.numSuffix(h2hd.golfRound)} RND</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'CRICKET' ?//nelsoncleancricket
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && /*h2hd.inningEvent <= 9 && */!h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} INN | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'CSGO2' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'LOL' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'DOTA2' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'OW' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'SOCCER' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} HALF | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'AUDL' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} HALF | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'VAL' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''

                                        }
                                        <img src={h2hd.player1.image} alt={h2hd.player1.firstName}/>
                                        <h3>
                                            {h2hd.player1.firstName} {h2hd.player1.lastName}
                                            {h2hd.team1locationType === 'home' && h2hd.player1.leagueType !== "UFC"
                                                ? (<span>{h2hd.player1.positionAbbreviation}-{h2hd.player1.teamAbbr}| {h2hd.team2Abbr} @ {h2hd.team1Abbr}</span>)
                                                : this.getMatchupStylingHomePlayer1(h2hd.team1locationType, h2hd.player1.leagueType, h2hd)
                                            }
                                            {h2hd.team1locationType === 'away' && h2hd.player1.leagueType !== "UFC"
                                                ? (<span>{h2hd.player1.positionAbbreviation}-{h2hd.player1.teamAbbr}| {h2hd.team1Abbr} @ {h2hd.team2Abbr}</span>)
                                                : this.getMatchupStylingAwayPlayer1(h2hd.team1locationType, h2hd.player1.leagueType, h2hd)
                                            }
                                        </h3>
                                        <h4>
                                            Live Points: <strong>{h2hd.livePropPoints + h2hd.player1Advantage}</strong>
                                        </h4>
                                        <h4>
                                            Total ({this.propsSort(h2hd.propParameters, 'player1')})
                                            <span> +{h2hd.player1Advantage}</span>
                                        </h4>
                                    </div>
                                    <div className="vs">
                                        <span>VS</span>
                                    </div>
                                    <div className="player" onClick={() => this.triggerPlayerStatsPopup(h2hd.player2)}>
                                        {
                                            h2hd.player2.leagueType === 'MLB' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && h2hd.inningEvent2 <= 9 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} INN | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'NFL' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && h2hd.inningEvent2 <= 4 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} QTR | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'NBA' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && h2hd.inningEvent2 <= 4 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} QTR | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'GOLF' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.numSuffix(h2hd.golfRound)} RND</span> : ''}
                                                    {!h2hd.event2Complete && h2hd.inningEvent2 !== 0 && h2hd.inningEvent2 != null ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} HOLE | {this.numSuffix(h2hd.golfRound)} RND</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.numSuffix(h2hd.golfRound)} RND</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'CRICKET' ?//nelsoncleancricket
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && /*h2hd.inningEvent2 <= 9 && */!h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} INN | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'CSGO2' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} RND | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'LOL' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} RND | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'DOTA2' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} RND | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'OW' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} RND | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'SOCCER' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} HALF | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'AUDL' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} HALF | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'VAL' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} RND | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        <img src={h2hd.player2.image} alt={h2hd.player2.firstName}/>
                                        <h3>
                                            {h2hd.player2.firstName} {h2hd.player2.lastName}
                                            {h2hd.team1locationType === 'home' && h2hd.player2.leagueType !== "UFC"
                                                ? (<span>{h2hd.player2.positionAbbreviation}-{h2hd.player2.teamAbbr}| {h2hd.team2Abbr} @ {h2hd.team1Abbr}</span>)
                                                : this.getMatchupStylingHomePlayer2(h2hd.team2locationType, h2hd.player2.leagueType, h2hd)
                                            }
                                            {h2hd.team1locationType === 'away' && h2hd.player2.leagueType !== "UFC"
                                                ? (<span>{h2hd.player2.positionAbbreviation}-{h2hd.player2.teamAbbr}| {h2hd.team1Abbr} @ {h2hd.team2Abbr}</span>)
                                                : this.getMatchupStylingAwayPlayer2(h2hd.team2locationType, h2hd.player2.leagueType, h2hd)
                                            }
                                        </h3>
                                        <h4>
                                            Live Points: <strong>{h2hd.livePropPointsPlayer2}</strong>
                                        </h4>
                                        <h4>
                                            Total ({this.propsSort(h2hd.propParameters, 'player2')})
                                        </h4>
                                    </div>


                                    {!h2hd.event1Complete || !h2hd.event2Complete ?
                                        <div className="my-picks">
                                            <h3 className="mobile">My Pick:</h3>
                                            {h2hd.isOver ? <h4>{h2hd.player1.firstName} {h2hd.player1.lastName}</h4> : <h4>{h2hd.player2.firstName} {h2hd.player2.lastName}</h4>}
                                            <div>
                                                {h2hd.isOver ?
                                                    <span className={h2hd.livePropPoints + h2hd.player1Advantage < h2hd.livePropPointsPlayer2 ? 'blue' : 'green'}
                                                          rel={h2hd.player1Points.toString()}>
                                                    Over = {h2hd.player1Points} PTS
                                                </span>
                                                    :
                                                    <span className={h2hd.livePropPointsPlayer2 < h2hd.livePropPoints + h2hd.player1Advantage ? 'blue' : 'green'}
                                                          rel={h2hd.player2Points.toString()}>
                                                    Under = {h2hd.player2Points} PTS
                                                </span>
                                                }
                                                <div>Owned {h2hd.ownedPercentage}%</div>
                                            </div>
                                        </div>
                                        :
                                        <div className="my-picks">
                                            <h3 className="mobile">My Pick:</h3>
                                            {h2hd.isOver ? <h4>{h2hd.player1.firstName} {h2hd.player1.lastName}</h4> : <h4>{h2hd.player2.firstName} {h2hd.player2.lastName}</h4>}
                                            <div>
                                                {h2hd.isOver ?
                                                    <span className={h2hd.livePropPoints + h2hd.player1Advantage < h2hd.livePropPointsPlayer2 ? 'red' : 'green'}
                                                          rel={h2hd.player1Points.toString()}>
                                                   {h2hd.player1Points} PTS
                                                </span>
                                                    :
                                                    <span className={h2hd.livePropPointsPlayer2 < h2hd.livePropPoints + h2hd.player1Advantage ? 'red' : 'green'}
                                                          rel={h2hd.player2Points.toString()}>
                                                  {h2hd.player2Points} PTS
                                                </span>
                                                }
                                                <div>Owned {h2hd.ownedPercentage}%</div>
                                            </div>
                                        </div>
                                    }
                                    {h2hd.oppenent?.length > 0 ?
                                        (!h2hd.oppenent[0]?.event1Complete || !h2hd.oppenent[0]?.event2Complete) ?
                                            <div className="opponents">
                                                <h3 className="mobile">Opponents Pick:</h3>
                                                {h2hd.oppenent[0]?.isOver ? <h4>{h2hd.oppenent[0]?.player1.firstName} {h2hd.oppenent[0]?.player1.lastName}</h4> :
                                                    <h4>{h2hd.oppenent[0]?.player2.firstName} {h2hd.oppenent[0]?.player2.lastName}</h4>}
                                                <div>
                                                    {h2hd.oppenent[0]?.isOver ?
                                                        <span
                                                            className={h2hd.oppenent[0]?.livePropPointsPlayer2 > h2hd.oppenent[0]?.livePropPoints + h2hd.oppenent[0]?.player1Advantage ? 'blue' : 'green'} rel={h2hd.oppenent[0]?.player1Points.toString()}>
                                                    {h2hd.oppenent[0]?.player1Points} PTS
                                                </span>
                                                        :
                                                        <span
                                                            className={h2hd.livePropPointsPlayer2 < h2hd.oppenent[0]?.livePropPoints + h2hd.oppenent[0]?.player1Advantage ? 'blue' : 'green'} rel={h2hd.oppenent[0]?.player2Points.toString()}>
                                                      {h2hd.oppenent[0]?.player2Points} PTS
                                                    </span>
                                                    }
                                                    <div>Owned {h2hd.oppenent[0]?.ownedPercentage}%</div>
                                                </div>
                                            </div>
                                            :
                                            <div className="opponents">
                                                <h3 className="mobile">Opponents Pick:</h3>
                                                {h2hd.oppenent[0]?.isOver ? <h4>{h2hd.oppenent[0]?.player1.firstName} {h2hd.oppenent[0]?.player1.lastName}</h4> :
                                                    <h4>{h2hd.oppenent[0]?.player2.firstName} {h2hd.oppenent[0]?.player2.lastName}</h4>}
                                                <div>
                                                    {h2hd.oppenent[0]?.isOver ?
                                                        <span
                                                            className={h2hd.oppenent[0]?.livePropPointsPlayer2 > h2hd.oppenent[0]?.livePropPoints + h2hd.oppenent[0]?.player1Advantage ? 'red' : 'green'} rel={h2hd.oppenent[0]?.player1Points.toString()}>
                                                    {h2hd.oppenent[0]?.player1Points} PTS
                                                </span>
                                                        :
                                                        <span
                                                            className={h2hd.livePropPointsPlayer2 < h2hd.oppenent[0]?.livePropPoints + h2hd.oppenent[0]?.player1Advantage ? 'red' : 'green'} rel={h2hd.oppenent[0]?.player2Points.toString()}>
                                                      {h2hd.oppenent[0]?.player2Points} PTS
                                                    </span>
                                                    }
                                                    <div>Owned {h2hd.oppenent[0]?.ownedPercentage}%</div>
                                                </div>
                                            </div>
                                        :
                                        <div className="opponents"></div>
                                    }
                                </li>
                            )) : ''}
                            {this.state.oppenentArray.length ? this.state.oppenentArray.map(h2hd => (
                                <li key={h2hd.propId}>
                                    <div className="player" onClick={() => this.triggerPlayerStatsPopup(h2hd.player1)}>
                                        {
                                            h2hd.player1.leagueType === 'MLB' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && h2hd.inningEvent <= 9 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} INN | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'NFL' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && h2hd.inningEvent <= 4 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} QTR | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'NBA' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && h2hd.inningEvent <= 4 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} QTR | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'GOLF' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.numSuffix(h2hd.golfRound)} RND</span> : ''}
                                                    {!h2hd.event1Complete && h2hd.inningEvent !== 0 && h2hd.inningEvent != null ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} HOLE | {this.numSuffix(h2hd.golfRound)} RND</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.numSuffix(h2hd.golfRound)} RND</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'CRICKET' ?//nelsoncleancricket
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && /*h2hd.inningEvent <= 9 && */!h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} INN | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'CSGO2' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'LOL' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'DOTA2' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'OW' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'SOCCER' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} HALF | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'AUDL' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} HALF | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'VAL' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd, false)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd, false)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        <img src={h2hd.player1.image} alt={h2hd.player1.firstName}/>
                                        <h3>
                                            {h2hd.player1.firstName} {h2hd.player1.lastName}
                                            {h2hd.team1locationType === 'home' && h2hd.player1.leagueType !== "UFC"
                                                ? (<span>{h2hd.player1.positionAbbreviation}-{h2hd.player1.teamAbbr}| {h2hd.team2Abbr} @ {h2hd.team1Abbr}</span>)
                                                : this.getMatchupStylingHomePlayer1(h2hd.player1.locationType, h2hd.player1.leagueType, h2hd)
                                            }
                                            {h2hd.team1locationType === 'away' && h2hd.player1.leagueType !== "UFC"
                                                ? (<span>{h2hd.player1.positionAbbreviation}-{h2hd.player1.teamAbbr}| {h2hd.team1Abbr} @ {h2hd.team2Abbr}</span>)
                                                : this.getMatchupStylingAwayPlayer1(h2hd.player1.locationType, h2hd.player1.leagueType, h2hd)
                                            }
                                        </h3>
                                        <h4>
                                            Live Points: <strong>{h2hd.livePropPoints + h2hd.player1Advantage}</strong>
                                        </h4>
                                        <h4>
                                            Total ({this.propsSort(h2hd.propParameters, 'player1')})
                                            <span> +{h2hd.player1Advantage}</span>
                                        </h4>
                                    </div>
                                    <div className="vs">
                                        <span>VS</span>
                                    </div>
                                    <div className="player" onClick={() => this.triggerPlayerStatsPopup(h2hd.player2)}>
                                        {
                                            h2hd.player2.leagueType === 'MLB' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && h2hd.inningEvent2 <= 9 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} INN | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'NFL' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && h2hd.inningEvent2 <= 4 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} QTR | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'NBA' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && h2hd.inningEvent2 <= 4 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} QTR | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'GOLF' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.numSuffix(h2hd.golfRound)} RND</span> : ''}
                                                    {!h2hd.event2Complete && h2hd.inningEvent2 !== 0 && h2hd.inningEvent2 != null ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} HOLE | {this.numSuffix(h2hd.golfRound)} RND</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.numSuffix(h2hd.golfRound)} RND</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'CRICKET' ?//nelsoncleancricket
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && /*h2hd.inningEvent2 <= 9 && */!h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} INN | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'CSGO2' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} RND | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'LOL' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} RND | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'DOTA2' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} RND | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'OW' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} RND | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'SOCCER' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} HALF | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'AUDL' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} HALF | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player2.leagueType === 'VAL' ?
                                                <span>
                                                    {h2hd.inningEvent2 === null || h2hd.inningEvent2 === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent2}</Moment> | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.inningEvent2 >= 1 && !h2hd.event2Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent2)} RND | {this.teamAbr(h2hd, true)}</span> : ''}
                                                    {h2hd.event2Complete ? <span> Final | {this.teamAbr(h2hd, true)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        <img src={h2hd.player2.image} alt={h2hd.player2.firstName}/>
                                        <h3>
                                            {h2hd.player2.firstName} {h2hd.player2.lastName}
                                            {h2hd.team1locationType === 'home' && h2hd.player2.leagueType !== "UFC"
                                                ? (<span>{h2hd.player2.positionAbbreviation}-{h2hd.player2.teamAbbr}| {h2hd.team2Abbr} @ {h2hd.team1Abbr}</span>)
                                                : this.getMatchupStylingHomePlayer2(h2hd.player2.locationType, h2hd.player2.leagueType, h2hd)
                                            }
                                            {h2hd.team1locationType === 'away' && h2hd.player2.leagueType !== "UFC"
                                                ? (<span>{h2hd.player2.positionAbbreviation}-{h2hd.player2.teamAbbr}| {h2hd.team1Abbr} @ {h2hd.team2Abbr}</span>)
                                                : this.getMatchupStylingAwayPlayer2(h2hd.player2.locationType, h2hd.player2.leagueType, h2hd)
                                            }
                                        </h3>
                                        <h4>
                                            Live Points: <strong>{h2hd.livePropPointsPlayer2}</strong>
                                        </h4>
                                        <h4>
                                            Total ({this.propsSort(h2hd.propParameters, 'player2')})
                                        </h4>
                                    </div>


                                    {h2hd.oppenent?.length > 0 ?
                                        <div className="my-picks">
                                            <h3 className="mobile">My Pick:</h3>
                                            <h4>{h2hd.oppenent[0]?.player2.firstName} {h2hd.oppenent[0]?.player2.lastName}</h4>
                                            <div>
                                                <span className={h2hd.livePropPoints + h2hd.player1Advantage > h2hd.oppenent[0]?.livePropPointsPlayer2 ? 'red' : 'green'}
                                                      rel={h2hd.player2Points.toString()}>
                                                   {h2hd.oppenent[0]?.player2Points} PTS
                                                </span>
                                                <div>Owned {h2hd.ownedPercentage}%</div>
                                            </div>
                                        </div>
                                        : <div className="my-picks">&nbsp;</div>
                                    }

                                    {!h2hd.event1Complete || !h2hd.event2Complete ?
                                        <div className="opponents">
                                            <h3 className="mobile">Opponents Pick:</h3>
                                            {h2hd.isOver ? <h4>{h2hd.player1.firstName} {h2hd.player1.lastName}</h4> : <h4>{h2hd.player2.firstName} {h2hd.player2.lastName}</h4>}
                                            <div>
                                                {h2hd.isOver ?
                                                    <span className={h2hd.livePropPoints + h2hd.player1Advantage < h2hd.livePropPointsPlayer2 ? 'blue' : 'green'}
                                                          rel={h2hd.player1Points.toString()}>
                                                    Over = {h2hd.player1Points} PTS
                                                </span>
                                                    :
                                                    <span className={h2hd.livePropPointsPlayer2 < h2hd.player1Advantage + h2hd.livePropPoints ? 'blue' : 'green'}
                                                          rel={h2hd.player2Points.toString()}>
                                                   {h2hd.player2Points} PTS
                                                </span>
                                                }
                                                <div>Owned {h2hd.ownedPercentage}%</div>
                                            </div>
                                        </div>
                                        :
                                        <div className="opponents">
                                            <h3 className="mobile">Opponents Pick:</h3>
                                            {h2hd.isOver ? <h4>{h2hd.player1.firstName} {h2hd.player1.lastName}</h4> : <h4>{h2hd.player2.firstName} {h2hd.player2.lastName}</h4>}
                                            <div>
                                                {h2hd.isOver ?
                                                    <span className={h2hd.livePropPoints + h2hd.player1Advantage < h2hd.livePropPointsPlayer2 ? 'red' : 'green'}
                                                          rel={h2hd.player1Points.toString()}>
                                                    Over = {h2hd.player1Points} PTS
                                                </span>
                                                    :
                                                    <span className={h2hd.livePropPointsPlayer2 < h2hd.player1Advantage + h2hd.livePropPoints ? 'red' : 'green'}
                                                          rel={h2hd.player2Points.toString()}>
                                                    {h2hd.player2Points} PTS
                                                </span>
                                                }
                                                <div>Owned {h2hd.ownedPercentage}%</div>
                                            </div>
                                        </div>
                                    }

                                </li>
                            )) : ''}
                        </ul>
                    </div>
                    <div className="totals">
                        <div>Earned points:</div>
                        <div>{this.state.myPoints}</div>
                        <div>{this.state.opponentPoints}</div>
                    </div>
                </div>
                <PlayerStatsPopup ref={this.playerStats}/>
                <Footer/>
            </main>
        );
    }
}


export default withRouter(MyContestVersusHeadToHead);
