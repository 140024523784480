import React from "react";
import "./wallet.scss";
import LoggedInHeader from "../Logged-in-header/Logged-in-header";
import $, { ajaxSetup } from "jquery";
import { isAuthenticated } from "../isAuthenticated";
import Moment from "react-moment";
import Footer from "../footer/footer";
import { messages } from "../messages";
import { Mixpanel } from "../mixpanel/Mixpanel";
import PrizeoutButton from "../../assets/images/PrizeoutButton.png";
import axios from "axios";

const requestOptions = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Token: window.localStorage.getItem("userData")
      ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
      : false,
  },
};

class Wallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      balance: "",
      amount: 0,
      withdrawalHistory: [],
      customAmount: 0,
      promoCode: "",
      reasonCode: false,
    };

    this.getWalletBalance = this.getWalletBalance.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.checkLocation = this.checkLocation.bind(this);
    this.checkReasonCodes = this.checkReasonCodes.bind(this);
    this.submitWithdrawEarning = this.submitWithdrawEarning.bind(this);
    this.fetchWithdrawalHistoryData =
      this.fetchWithdrawalHistoryData.bind(this);
    this.confirmWithdrawal = this.confirmWithdrawal.bind(this);
    this.cancelWithdrawal = this.cancelWithdrawal.bind(this);
    this.closePaymentModal = this.closePaymentModal.bind(this);
    this.selectAmount = this.selectAmount.bind(this);
    this.handleAmountEntered = this.handleAmountEntered.bind(this);
    this.handlePromoChange = this.handlePromoChange.bind(this);
    this.firstAmount = React.createRef();
    this.secondAmount = React.createRef();
    this.thirdAmount = React.createRef();
    this.fourthAmount = React.createRef();
    this.fifthAmount = React.createRef();
  }

  getWalletBalance() {
    fetch(
      process.env.REACT_APP_SERVER_URL + "users/get-wallet-balance",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["success"]) {
          $(".user-wallet").html(
            formatNumber(data["response"]["walletBalance"])
          );
          this.setState({ balance: data["response"]["walletBalance"] });
        } else {
          messages("error", data["message"]);
        }
      })
      .catch((error) => {
        messages("error", error);
      });
  }

  fetchWithdrawalHistoryData() {
    const withdrawalHistoryBody = {
      contestId: 0,
      userContestId: 500,
    };

    const withdrawalHistoryOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Token: window.localStorage.getItem("userData")
          ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
          : false,
      },
      body: JSON.stringify(withdrawalHistoryBody),
    };

    fetch(
      process.env.REACT_APP_SERVER_URL + "users/user-pending-refund-listing",
      withdrawalHistoryOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["success"]) {
          this.setState({ withdrawalHistory: data["response"]["data"] });
          $(".loader").removeClass("show");
        } else {
          messages("error", data["message"]);
          $(".loader").removeClass("show");
        }
      });
  }

  componentDidMount() {
    isAuthenticated("login", "/wallet");
    this.getWalletBalance();
    this.fetchWithdrawalHistoryData();

    Mixpanel.track("web:wallet_opened");

    if (window.localStorage.getItem("fantasyAlarmLink") !== "") {
      axios
        .get(window.localStorage.getItem("fantasyAlarmLink"))
        .then(window.localStorage.setItem("fantasyAlarmLink", ""));
    }

    if (window.localStorage.getItem("rotogrindersLink") !== "") {
      axios
          .get(window.localStorage.getItem("rotogrindersLink"))
          .then(window.localStorage.setItem("rotogrindersLink", ""));
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    // console.log('Value: ' + target.value + '\nName: ' + target.name);
    // Mixpanel.track('web:user_')
  }

  checkLocation() {
    $(".loader").addClass("show");

    navigator.geolocation.getCurrentPosition(
      (position) => {
        let locationCheckBody = {
          Latitude: position.coords.latitude,
          Longitude: position.coords.longitude,
        };

        const locationCheckOptions = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Token: window.localStorage.getItem("userData")
              ? JSON.parse(window.localStorage.getItem("userData"))[
                  "accessToken"
                ]
              : false,
          },
          body: JSON.stringify(locationCheckBody),
        };

        fetch(
          process.env.REACT_APP_SERVER_URL + "users/customer-identity-service",
          locationCheckOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data["success"]) {
              let hidePaypal = Boolean(data["response"]["hidePayPalButton"]);

              if (hidePaypal === true) {
                this.setState({
                  reasonCode: true,
                });
              }

              this.checkReasonCodes(
                data["response"]["responseMessage"],
                data["response"]["locationDetail"]["ReasonCodes"]
              );
            } else {
              $(".loader").removeClass("show");

              messages("error", data["message"]);
            }
          });
      },
      (error) => {
        $(".loader").removeClass("show");
        messages("error", "Permission Denied: Unable to Determine Location.");
      },
      { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }
    );

    Mixpanel.track("web:user_clicked_continue_deposit", {
      "Promo Code": this.state.promoCode,
    });
  }

  checkReasonCodes(responseMessage, reasonCodes) {
    if (responseMessage === "No error.") {
      if (
        reasonCodes.indexOf("LL-GEO-US-NE") > -1 &&
        reasonCodes.indexOf("ID-UA-19") > -1
      ) {
        $(".loader").removeClass("show");
        messages(
          "error",
          "Permission Denied Because You Are In An Illegal State."
        );
        return false;
      }
      if (
        reasonCodes.indexOf("LL-GEO-US-MA") > -1 &&
        reasonCodes.indexOf("ID-UA-21") > -1
      ) {
        $(".loader").removeClass("show");
        messages(
          "error",
          "Permission Denied Because You Are In An Illegal State."
        );
        return false;
      }

      for (var i = 0; i < reasonCodes.length; i++) {
        if (
          reasonCodes[i].indexOf("LL-BLOCK") > -1 ||
          reasonCodes[i].indexOf("LL-FAIL") > -1 ||
          reasonCodes[i].indexOf("ID-UNKN") > -1 ||
          reasonCodes[i].indexOf("ID-FAIL") > -1 ||
          reasonCodes[i].indexOf("ID-UA18") > -1 ||
          reasonCodes[i].indexOf("LL-UNKN") > -1
        ) {
          $(".loader").removeClass("show");
          messages(
            "error",
            "Permission Denied Because You Are In An Illegal State."
          );
          return false;
        }
      }
      this.getPaymentSession();
    }
  }

  getPaymentSession() {
    let amount = this.state.amount;
    if (amount < 1) {
      messages("error", "Please enter valid amount");
      $(".loader").removeClass("show");
      return;
    }
    var latitude;
    var longitude;
    amount = parseFloat(amount).toFixed(2);
    navigator.geolocation.getCurrentPosition((position) => {
      latitude = position.coords.latitude;
      longitude = position.coords.longitude;
    });

    let PaymentSessionBody = {
      PayActionCode: "PAY",
      CashierPaymentAmount: {
        PaymentAmount: amount,
        PaymentAmountOverride: true,
        PaymentCurrencyCode: "USD",
      },
      Latitude: latitude,
      Longitude: longitude,
      PromoCode: this.state.promoCode,
      CallbackURL: process.env.REACT_APP_SERVER_URL + "payment/callback",
    };

    const PaymentSessionOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Token: window.localStorage.getItem("userData")
          ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
          : false,
      },
      body: JSON.stringify(PaymentSessionBody),
    };

    fetch(
      process.env.REACT_APP_SERVER_URL + "payment/session",
      PaymentSessionOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["success"]) {
          this.getScriptData(
            data["response"]["SessionURL"],
            data["response"]["MerchantSessionID"]
          );
        } else {
          $(".loader").removeClass("show");
          messages("error", data["message"]);
          Mixpanel.track("web:wallet_threw_error", {
            Error: data["message"],
          });
        }
      });
  }

  getScriptData(data, MerchantSessionID) {
    let createSessionWebBody = {
      scriptTag: data,
      sessionId: MerchantSessionID,
    };

    const createSessionWebOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Token: window.localStorage.getItem("userData")
          ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
          : false,
      },
      body: JSON.stringify(createSessionWebBody),
    };

    fetch(
      process.env.REACT_APP_SERVER_URL + "payment/create-session-web",
      createSessionWebOptions
    )
      .then((response) => response.text())
      .then((data) => {
        let script = data;
        script = script.replace(
          "accessToken",
          '"' +
            JSON.parse(window.localStorage.getItem("userData"))["accessToken"] +
            '"'
        );
        this.openPaymentModal(script);
        $(".loader").removeClass("show");
      })
      .catch((error) => {
        $(".loader").removeClass("show");
        messages("error", error);
      });
  }

  openPaymentModal(script) {
    $(".payment-popup").addClass("show");
    $(".payment-popup .script-body").html(script);
  }

  closePaymentModal() {
    this.getWalletBalance();
    $(".payment-popup").removeClass("show");

    Mixpanel.track("web:pay_now_window_closed");
  }

  tabSwitch(event) {
    const tab = event.target.getAttribute("rel");
    $(".tabs-heads").find("button").removeClass("active");
    event.target.classList.add("active");
    $(".tabs-body").find("div").removeClass("active");
    $(".tabs-body")
      .find('div[rel="' + tab + '"]')
      .addClass("active");

    var tabName = "";
    if (tab === 1) {
      tabName = "Add Funds";
    } else if (tab === 2) {
      tabName = "Withdraw Funds";
    } else if (tab === 3) {
      tabName = "Withdraw History";
    }

    Mixpanel.track("web:user_switched_wallet_tab", {
      Tab: tabName,
    });
  }

  submitWithdrawEarning() {
    const balance = this.state.balance;
    let withdrawEarning = this.state.withdrawEarning;
    const paypalEmail = this.state.paypalEmail;
    const accountHolderName = this.state.accountHolderName;

    $(".loader").addClass("show");

    Mixpanel.track("web:user_selected_submit_withdraw_funds", {
      Balance: balance,
      "Withdraw Earning": withdrawEarning,
      "Paypal Email": paypalEmail,
      "Account Holder Name": accountHolderName,
    });

    if (withdrawEarning <= 0) {
      $(".loader").removeClass("show");
      messages("error", "Please enter valid amount");
      return;
    }
    if (withdrawEarning > balance) {
      $(".loader").removeClass("show");
      messages(
        "error",
        "Entered amount should not be greater than wallet balance"
      );
      return;
    }

    withdrawEarning = parseFloat(withdrawEarning).toFixed(2);

    if (balance <= 0) {
      $(".loader").removeClass("show");
      messages(
        "error",
        "You have insufficient balance to withdraw your balance"
      );
      return;
    }

    let withdrawEarningBody = {
      withdrawEarning: withdrawEarning,
      paypalEmail: paypalEmail,
      accountHolderName: accountHolderName,
    };

    const withdrawEarningOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Token: window.localStorage.getItem("userData")
          ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
          : false,
      },
      body: JSON.stringify(withdrawEarningBody),
    };

    fetch(
      process.env.REACT_APP_SERVER_URL + "users/withdraw-earning",
      withdrawEarningOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["success"]) {
          this.setState({
            withdrawEarning: "",
            paypalEmail: "",
            accountHolderName: "",
          });
          $(".tab-withdraw-funds")
            .find("input")
            .each(function () {
              $(this).val("");
            });
          $(".loader").removeClass("show");
          messages(
            "success",
            "Your withdrawal request has been sent to the admin for approval. The approval will be further validated by admin and processed accordingly."
          );
        } else {
          $(".loader").removeClass("show");
          messages("error", data["message"]);
        }
      })
      .catch((error) => {
        $(".loader").removeClass("show");
        messages("error", error);
      });
  }

  confirmWithdrawal(payoutId) {
    this.setState({ payoutId: payoutId });
    $(".cancel-withdraw-popup").addClass("show");
  }

  closeCancelWithdrawPopup() {
    $(".cancel-withdraw-popup").removeClass("show");
  }

  cancelWithdrawal() {
    $(".loader").addClass("show");
    const payoutId = this.state.payoutId;

    let cancelWithdrawEarningBody = {
      payoutId: payoutId,
    };

    const cancelWithdrawEarningOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Token: window.localStorage.getItem("userData")
          ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
          : false,
      },
      body: JSON.stringify(cancelWithdrawEarningBody),
    };

    fetch(
      process.env.REACT_APP_SERVER_URL + "users/re-withdraw",
      cancelWithdrawEarningOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["success"]) {
          this.closeCancelWithdrawPopup();
          this.getWalletBalance();
          this.fetchWithdrawalHistoryData();
          messages("success", data["message"]);
        } else {
          messages("error", data["message"]);
        }
        $(".loader").removeClass("show");
      })
      .catch((error) => {
        $(".loader").removeClass("show");
        messages("error", error);
      });
  }

  selectAmount(amount, isOther) {
    console.log(amount);
    var refs = [
      this.firstAmount.current,
      this.secondAmount.current,
      this.thirdAmount.current,
      this.fourthAmount.current,
      this.fifthAmount.current,
    ];
    var amounts = ["20", "100", "250", "500", "other"];
    this.state.amount = parseFloat(amount);

    Mixpanel.track("web:user_selected_deposit_amount", {
      Amount: amount,
    });

    var idx = 0;
    switch (amount) {
      case "20":
        idx = 0;
        this.state.amount = 20;
        break;
      case "100":
        idx = 1;
        this.state.amount = 100;
        break;
      case "250":
        idx = 2;
        this.state.amount = 250;
        break;
      case "500":
        idx = 3;
        this.state.amount = 500;
        break;
      case "other":
        idx = 4;
        this.state.amount = this.state.customAmount;
        break;
    }

    for (var i = 0; i < refs.length; i++) {
      const node = refs[i];
      if (i === idx) {
        node.style.borderStyle = "solid";
        node.style.borderColor = "#00B7EC";
        if (i === 1 || i === 3) {
          node.style.borderWidth = "10px";
        }
        if (i !== 4) node.style.lineHeight = "80px";
        else node.style.lineHeight = "45px";
      } else {
        if (i === 1 || i === 3) {
          node.style.borderStyle = "solid";
          node.style.borderColor = "#00bf61";
          node.style.borderWidth = "5px";
        } else {
          node.style.borderStyle = "none";
          if (i !== 4) node.style.lineHeight = "100px";
          else node.style.lineHeight = "50px";
        }
      }
    }
  }

  handleAmountEntered(event) {
    this.state.customAmount = event.target.value;
    this.state.amount = this.state.customAmount;
  }

  handlePromoChange(event) {
    this.state.promoCode = event.target.value;
    // Mixpanel.track('web:user_entered_deposit_promo_code', {
    //     'Promo Code':event.target.value
    // })
  }

  initializePrizeout(balance, prizeoutData) {
    let email = prizeoutData.userDto["email"];
    let userId = JSON.parse(window.localStorage.getItem("userData"))["userDto"]
      .userId;
    // console.log(balance, email, userId.toString())
    window.prizeoutSDK.init({
      env: "prod",
      user: {
        year_of_birth: "1950",
        email: email,
        user_id: userId.toString(),
        // "session_id": "12341234-1234-1234-123412341234",
        country: "US",
        region: "US",
        balance: balance * 100, // user balance * 100 = prizeout balance
      },
      publisher: {
        name: "ThriveFantasy",
        id: "d093e802-21bf-4504-9733-e245570a0b4c",
        apikey: "3f9479d28dc24a78d59e85d1eca256ea",
      },
      callbacks: {
        // "onInit": "function(payload) { ftl.log('Widget Started', payload); }",
        // "onClose": "function(payload) { ftl.log('Widget Closed', payload); }"
      },
    });
  }

  render() {
    let prizeoutData = JSON.parse(window.localStorage.getItem("userData"));

    // console.log("CHECK THIS: " + this.state.reasonCode);

    return (
      <main className="wallet-pages">
        <LoggedInHeader />
        <div className="inner">
          <h1>
            Wallet:{" "}
            {this.state.balance ? (
              <span>{formatNumber(this.state.balance)}</span>
            ) : (
              ""
            )}
          </h1>
          <div className="wallet-tabs-container">
            <div className="tabs-heads">
              <button onClick={this.tabSwitch} className="active" rel="1">
                Add Funds
              </button>
              <button onClick={this.tabSwitch} rel="2">
                Withdraw Funds
              </button>
              <button onClick={this.tabSwitch} rel="3">
                Withdrawal History
              </button>
            </div>
            <div className="tabs-body">
              <div rel="1" className="tab-add-funds active">
                <h2 class="wallet-title">Select Deposit Amount</h2>
                <p class="highlight-text-recommended">Recommended</p>
                <p class="highlight-text-best-offer">Best Offer!</p>
                <div class="deposit-options">
                  <span
                    onClick={() => this.selectAmount("20")}
                    id="20"
                    ref={this.firstAmount}
                  >
                    $20
                  </span>
                  <span
                    class="highlight"
                    onClick={() => this.selectAmount("100")}
                    id="100"
                    ref={this.secondAmount}
                  >
                    $100
                  </span>
                  <span
                    onClick={() => this.selectAmount("250")}
                    id="250"
                    ref={this.thirdAmount}
                  >
                    $250
                  </span>
                  <span
                    class="highlight"
                    onClick={() => this.selectAmount("500")}
                    id="500"
                    ref={this.fourthAmount}
                  >
                    $500
                  </span>
                  <span
                    onClick={() => this.selectAmount("other")}
                    id="other"
                    ref={this.fifthAmount}
                  >
                    Other
                    <input
                      type="number"
                      class="form-control"
                      size="30"
                      name="amount"
                      onChange={this.handleAmountEntered}
                    />
                  </span>
                </div>
                <p class="highlight-text-bonus-1">
                  + 2 FREE $20 <br></br> Contest Tickets*
                </p>
                <p class="highlight-text-bonus-2">
                  + 6 FREE $20 <br></br> Contest Tickets*
                </p>
                <input
                  type="text"
                  size="40"
                  name="promoCode"
                  placeholder="Enter a Promo Code"
                  class="promoCodeInput"
                  onChange={this.handlePromoChange}
                />
                <button class="continuePayment" onClick={this.checkLocation}>
                  Continue
                </button>
                <p>$1000 max deposit.</p>
                <p>*Deposit match subject to a 4x rollover requirement</p>
                <p>
                  Successful deposits might take up to 2 minutes to show in
                  wallet.
                </p>
                <p>ACH/Echeck can take 3-5 days to successfully complete.</p>
                <p style={{ fontSize: 8 }}>
                  *Offer valid on the first deposit for new users only <br></br>{" "}
                  Cannot be combined with other offers
                </p>
              </div>
              <div className="withdraw-container">
                <div id="row1" rel="2" className="tab-withdraw-funds">
                  <div>
                    <span>$</span>
                    <input
                      type="number"
                      size="30"
                      name="withdrawEarning"
                      autoComplete="false"
                      placeholder="Amount ($20 Minimum)"
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div>
                    <span className="email"> </span>
                    <input
                      type="email"
                      name="paypalEmail"
                      autoComplete="false"
                      placeholder="Enter your Email Address"
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div>
                    <span className="user"> </span>
                    <input
                      type="text"
                      name="accountHolderName"
                      autoComplete="false"
                      placeholder="Enter your full name as per your bank account"
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <button onClick={this.submitWithdrawEarning}>Submit</button>
                </div>
                <div id="row2" rel="2" className="tab-withdraw-funds">
                  <h1>OR</h1>
                </div>
                <div id="row3" rel="2" className="tab-withdraw-funds">
                  <h1>
                    Withdraw Using{" "}
                    <a
                      onClick={() =>
                        this.initializePrizeout(
                          this.state.balance,
                          prizeoutData
                        )
                      }
                    >
                      <img
                        src={PrizeoutButton}
                        style={{ width: "370px", paddingRight: "40px" }}
                      ></img>
                    </a>
                  </h1>
                  <p
                    style={{
                      fontSize: 12,
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    <em>*All requests using Prizeout are final</em>
                  </p>
                </div>
              </div>
              <div rel="3" className="tab-withdraw-history ">
                <table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.withdrawalHistory.length ? (
                      this.state.withdrawalHistory.map((data) => (
                        <tr key={data["payoutId"]}>
                          {/*console.log(data)*/}
                          <td>
                            <Moment
                              utc
                              local
                              format="hh:mm a, ddd, MMM Do, YYYY"
                            >
                              {data["transactionTimestamp"]}
                            </Moment>
                          </td>
                          <td>{formatNumber(data["transactionAmount"])}</td>
                          <td>
                            {data["transactionStatus"] === 1 &&
                            data["email"] !== "PRIZEOUT REQUEST" ? (
                              <span>Complete</span>
                            ) : (
                              ""
                            )}
                            {data["transactionStatus"] === 2 &&
                            data["email"] !== "PRIZEOUT REQUEST" ? (
                              <span>Pending Transfer</span>
                            ) : (
                              ""
                            )}
                            {data["transactionStatus"] === 3 &&
                            data["email"] !== "PRIZEOUT REQUEST" ? (
                              <span>Failed</span>
                            ) : (
                              ""
                            )}
                            {data["transactionStatus"] === 4 &&
                            data["email"] !== "PRIZEOUT REQUEST" ? (
                              <span>Cancelled</span>
                            ) : (
                              ""
                            )}

                            {data["transactionStatus"] === 2 &&
                            data["email"] === "PRIZEOUT REQUEST" ? (
                              <span>Failed/Rejected (Prizeout)</span>
                            ) : (
                              ""
                            )}
                            {data["transactionStatus"] === 3 &&
                            data["email"] === "PRIZEOUT REQUEST" ? (
                              <span>Successful (Prizeout)</span>
                            ) : (
                              ""
                            )}

                            {data["transactionStatus"] === 0 &&
                            data["email"] !== "PRIZEOUT REQUEST" ? (
                              <span>
                                Pending Approval{" "}
                                <button
                                  onClick={() =>
                                    this.confirmWithdrawal(data["payoutId"])
                                  }
                                >
                                  Cancel
                                </button>
                              </span>
                            ) : (
                              ""
                            )}
                            {data["transactionStatus"] === (0 || 1) &&
                            data["email"] === "PRIZEOUT REQUEST" ? (
                              // <span>Pending Approval <button onClick={() => this.confirmWithdrawal(data['payoutId'])}>Cancel</button></span>
                              <span>Pending Approval (Prizeout) </span>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr> </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="payment-popup popup">
          <div className="inner">
            {this.state.reasonCode === true ? (
              <style jsx>
                {`
                  button[data-pmname="PayPal"] {
                    display: none;
                  }
                `}
              </style>
            ) : (
              ""
            )}

            <button
              onClick={this.closePaymentModal}
              className="close-payment-modal close"
              title="Close"
            ></button>
            <h2>Pay Now</h2>
            <div className="script-body"></div>
          </div>
        </div>
        <div className="cancel-withdraw-popup">
          <div className="inner">
            <h2>
              Your request for withdrawal will be cancelled. Are you sure you
              want to continue ?
            </h2>
            <div>
              <button onClick={this.closeCancelWithdrawPopup} title="Close">
                Close
              </button>
              <button onClick={this.cancelWithdrawal} title="OK">
                OK
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    );
  }
}

function formatNumber(num) {
  return "$" + (num * 1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export default Wallet;
