import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import "./Logged-in-header.scss";
import $ from "jquery";

//images
import logo from "../../assets/images/loginlogo.png";
import menu from "../../assets/images/menu.png";
import { messages } from "../messages";
import { Mixpanel } from "../mixpanel/Mixpanel";

const requestOptions = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Token: window.localStorage.getItem("userData")
      ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
      : false,
  },
};

class LoggedInHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      balance: 0.0,
      activePage: this.props.match.path,
      tickets: [],
      houseTickets: [],
      showTicketModal: false,
    };

    this.getWalletBalance = this.getWalletBalance.bind(this);
    this.showTicketModal = this.showTicketModal.bind(this);
    this.closeTicketModal = this.closeTicketModal.bind(this);
    this.getUserContestTickets = this.getUserContestTickets.bind(this);
    this.getUserHouseTickets = this.getUserHouseTickets.bind(this);
    this.ticketHeaderDisplay = this.ticketHeaderDisplay.bind(this);
    this.contestTicketLeagues = this.contestTicketLeagues.bind(this);
    this.contestTicketContests = this.contestTicketContests.bind(this);
    this.getContestTicketDollarAmount =
      this.getContestTicketDollarAmount.bind(this);
    this.getContestTicketExpirationDate =
      this.getContestTicketExpirationDate.bind(this);
    this.getHouseTicketDollarAmount =
      this.getHouseTicketDollarAmount.bind(this);
    this.getHouseTicketExpirationDate =
      this.getHouseTicketExpirationDate.bind(this);
    this.getNumberOfTickets = this.getNumberOfTickets.bind(this);
    this.listTicketInfo = this.listTicketInfo.bind(this);
  }

  getWalletBalance() {
    fetch(
      process.env.REACT_APP_SERVER_URL + "users/get-wallet-balance",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["success"]) {
          this.setState({ balance: data["response"]["walletBalance"] });
        } else {
          window.localStorage.removeItem("userData");
          window.location.href = process.env.REACT_APP_START_URL;
        }
      })
      .catch((error) => {
        messages("error", error);
      });
  }

  getNumberOfTickets() {
    if (this.state.tickets == null && this.state.houseTickets == null) {
      return 0;
    } else {
    //   console.log(
    //     "num of tickets: " +
    //       this.state.tickets.length +
    //       this.state.houseTickets.length
    //   );
      return this.state.tickets.length + this.state.houseTickets.length;
    }
  }

  getUserContestTickets() {
    fetch(
      process.env.REACT_APP_SERVER_URL + "tickets/getUsersTickets",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["success"]) {
        //   console.log("TICKET CALL SUCCESS");
        //   console.log(data["response"]);
          this.setState({ tickets: data["response"] });
        } else {
          this.setState({ tickets: [] });
        }
      })
      .catch((error) => {
        messages("error", error);
      });
  }

  getUserHouseTickets() {
    // fetch(process.env.REACT_APP_MICROSERVICE_URL + 'tickets/getUsersHouseTickets', requestOptions)
    fetch(
      process.env.REACT_APP_SERVER_URL + "tickets/getUsersHouseTickets",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["success"]) {
        //   console.log("HOUSE TICKET CALL SUCCESS");
        //   console.log(data["response"]);
          this.setState({ houseTickets: data["response"] });
        } else {
          this.setState({ houseTickets: [] });
        }
      })
      .catch((error) => {
        messages("error", error);
      });
  }

  setMonitoringInterval(check) {
    if (
      window.localStorage.getItem("userData") &&
      JSON.parse(window.localStorage.getItem("userData"))["accessToken"] !==
        null &&
      (check || window.sessionStorage.getItem("MonitoringInterval") === "true")
    ) {
      window.sessionStorage.setItem("MonitoringInterval", "false");
      fetch(
        process.env.REACT_APP_SERVER_URL + "users/customer-monitor-service",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // let setOutTime;
          setTimeout(() => {
            this.setMonitoringInterval(true);
          }, 1200000);
        })
        .catch((error) => {
          setTimeout(() => {
            this.setMonitoringInterval(true);
          }, 1200000);
        });
    }
  }

  ticketHeaderDisplay() {
    if (
      (this.state.tickets != null && this.state.tickets.length > 0) ||
      (this.state.houseTickets != null && this.state.houseTickets.length > 0)
    ) {
      return (
        this.state.tickets.length +
        this.state.houseTickets.length +
        " ticket(s)"
      );
    } else return null;
  }

  showTicketModal() {
    console.log("show ticket modal");
    // if this.stat.tickets is empty//nelson
    $(".ticket-modal").addClass("show");
  }
  closeTicketModal() {
    console.log("remove ticket modal");
    $(".ticket-modal").removeClass("show");
  }

  contestTicketLeagues(ticket) {
    var leagues = "";
    if (ticket === null) {
      return null;
    } else {
      for (const league of ticket["leagues"]) {
        leagues += league + " ";
      }
      return leagues;
    }
  }

  contestTicketContests(ticket) {
    var contestNames = "";
    if (ticket === null) {
      return null;
    } else {
      for (const contestName of ticket["contestNames"]) {
        contestNames += contestName + ", ";
      }
      const newText = contestNames.split(", ").map((str) => <p>{str}</p>);
      return newText;
    }
  }

  listTicketInfo(ticket) {
    if (ticket == null) {
      return null;
    }

    if (this.contestTicketLeagues(ticket) === "") {
      return (
        <ul>
          <li>
            <span className="ticketLabels">Applicable Contests: </span>
            <span className="ticketValues">
              {this.contestTicketContests(ticket)}
            </span>
          </li>
          <li>
            <span className="ticketLabels">Expiration Date: </span>
            <span className="ticketValues">
              {this.getContestTicketExpirationDate(ticket)}
            </span>
          </li>
        </ul>
      );
    } else {
      return (
        <ul>
          <li>
            <span className="ticketLabels">Applicable Leagues: </span>
            <span className="ticketValues">
              {this.contestTicketLeagues(ticket)}
            </span>
          </li>
          <li>
            <span className="ticketLabels">Dollar Value: </span>
            <span className="ticketValues">
              ${this.getContestTicketDollarAmount(ticket)}
            </span>
          </li>
          <li>
            <span className="ticketLabels">Expiration Date: </span>
            <span className="ticketValues">
              {this.getContestTicketExpirationDate(ticket)}
            </span>
          </li>
        </ul>
      );
    }
  }

  listHouseTicketInfo(houseTicket) {
    if (houseTicket == null) {
      return null;
    }

    return (
      <ul>
        <li>
          <span className="houseTicketLabels">Dollar Value: </span>
          <span className="houseTicketValues">
            ${this.getHouseTicketDollarAmount(houseTicket)}
          </span>
        </li>
        <li>
          <span className="houseTicketLabels">Expiration Date: </span>
          <span className="houseTicketValues">
            {this.getHouseTicketExpirationDate(houseTicket)}
          </span>
        </li>
      </ul>
    );
  }

  getContestTicketDollarAmount(ticket) {
    if (ticket === null) {
      return null;
    }
    return ticket["dollarAmount"];
  }

  getHouseTicketDollarAmount(houseTicket) {
    if (houseTicket === null) {
      return null;
    }
    return houseTicket["dollarAmount"];
  }

  getContestTicketExpirationDate(ticket) {
    if (ticket === null) {
      return null;
    }
    return new Date(ticket["expirationDate"]).toLocaleDateString();
  }

  getHouseTicketExpirationDate(houseTicket) {
    if (houseTicket === null) {
      return null;
    }
    return new Date(houseTicket["expirationDate"]).toLocaleDateString();
  }

  componentDidMount() {
    this.getWalletBalance();
    this.setMonitoringInterval(false);
    this.getUserContestTickets(); //nelson
    this.getUserHouseTickets();

    $(".user-menu").on("click", function (e) {
      e.stopPropagation();
      $(this).find("nav").toggleClass("open");
    });

    $("body").on("click", function (e) {
      $(".user-menu").find("nav").removeClass("open");
    });

    $(".logout").on("click", function () {
      Mixpanel.track("web:user_logged_out");
      Mixpanel.reset();

      window.localStorage.removeItem("userData");
      window.location.href = process.env.REACT_APP_START_URL;
    });

    $(".mobile-nav-btn").on("click", function () {
      $(".mobile-nav").toggleClass("open");
    });

    $(".close-mobile-nave").on("click", function () {
      $(".mobile-nav").removeClass("open");
    });

    $("body").on("click", ".popup", function (e) {
      if (e.target === this) {
        $(".popup").removeClass("show");
      }
    });
  }

  render() {
    return (
      <header className="logged-in-header">
        <div className="inner">
          <Link
            className="logo"
            to={process.env.REACT_APP_START_URL + "contest-lobby"}
            title="Thrive Fantasy"
          >
            <img src={logo} alt="Thrive Fantasy" />
          </Link>
          <nav className="logged-in-nav">
            <ul>
              <li
                className={
                  this.state.activePage.includes("contest-lobby")
                    ? "active"
                    : ""
                }
              >
                <Link
                  to={process.env.REACT_APP_START_URL + "contest-lobby"}
                  title="Contest Lobby"
                >
                  Contest Lobby
                </Link>
              </li>
              <li
                className={
                  this.state.activePage.includes("props-lobby") ? "active" : ""
                }
              >
                <Link
                  to={process.env.REACT_APP_START_URL + "props-lobby"}
                  title="Props Lobby"
                >
                  Prop Lobby
                </Link>
              </li>
              <li
                className={
                  this.state.activePage.includes("my-contests") ? "active" : ""
                }
              >
                <Link
                  to={process.env.REACT_APP_START_URL + "my-contests/live"}
                  title="My Contests"
                >
                  My Contests
                </Link>
              </li>
              <li
                className={
                  this.state.activePage.includes("my-props") ? "active" : ""
                }
              >
                <Link
                  to={process.env.REACT_APP_START_URL + "my-props/live"}
                  title="My Props"
                >
                  My Props
                </Link>
              </li>
            </ul>
          </nav>
          <div className="header-right">
            <a
              className="affiliate-btn"
              href="https://bit.ly/ThriveAffiliateForm"
              target="_blank"
            >
              Become an Affiliate!
            </a>
            <Link
              className="notifications"
              to={process.env.REACT_APP_START_URL + "notifications"}
              title="Notifications"
            >
              <span>Notifications</span>
            </Link>
            <button className="mobile-nav-btn">
              <img src={menu} alt="menu" />
            </button>
            <button
              if
              className="ticket-indicator"
              onClick={this.showTicketModal}
            >
              {this.ticketHeaderDisplay()}
            </button>
            <div className="ticket-modal">
              <div className="inner">
                <button
                  onClick={this.closeTicketModal}
                  className="close-ticket-modal close"
                  title="Close"
                ></button>
                <h2>You have {this.getNumberOfTickets()} Ticket(s)!</h2>
                <ul className="tickets-list">
                  {this.state.tickets.length > 0 && <h4> Ticket(s): </h4>}
                  {this.state.tickets.map((ticket) => {
                    return this.listTicketInfo(ticket);
                  })}
                  {this.state.houseTickets.length > 0 && (
                    <h4> House Ticket(s): </h4>
                  )}
                  {this.state.houseTickets.map((houseTicket) => {
                    return this.listHouseTicketInfo(houseTicket);
                  })}
                </ul>
              </div>
            </div>
            <div className="user-menu">
              <span>
                {window.localStorage.getItem("userData")
                  ? JSON.parse(window.localStorage.getItem("userData"))[
                      "userDto"
                    ]["userName"]
                  : ""}
              </span>
              <nav>
                <ul>
                  <li>
                    <Link
                      to={process.env.REACT_APP_START_URL + "settings"}
                      title="Settings"
                    >
                      Settings
                    </Link>
                  </li>
                  <li>
                    <button className="logout" title="Log Out">
                      Log Out
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
            <Link
              to={process.env.REACT_APP_START_URL + "wallet"}
              className="user-wallet"
              title="Wallet"
            >
              {formatNumber(this.state.balance)}
            </Link>
            <Link
              className="deposit-btn"
              to={process.env.REACT_APP_START_URL + "wallet"}
              title="Deposit Now"
            >
              Deposit
            </Link>
          </div>
          <div className="mobile-nav">
            <Link
              className="logo"
              to={process.env.REACT_APP_START_URL + "contest-lobby"}
              title="Thrive Fantasy"
            >
              <img src={logo} alt="Thrive Fantasy" />
            </Link>
            <button className="close-mobile-nave"></button>
            <ul>
              <li
                className={
                  this.state.activePage.includes("contest-lobby")
                    ? "active"
                    : ""
                }
              >
                <Link
                  to={process.env.REACT_APP_START_URL + "contest-lobby"}
                  title="Contest Lobby"
                >
                  Contest Lobby
                </Link>
              </li>
              <li
                className={
                  this.state.activePage.includes("props-lobby") ? "active" : ""
                }
              >
                <Link
                  to={process.env.REACT_APP_START_URL + "props-lobby"}
                  title="Props Lobby"
                >
                  Prop Lobby
                </Link>
              </li>
              <li
                className={
                  this.state.activePage.includes("my-contests") ? "active" : ""
                }
              >
                <Link
                  to={process.env.REACT_APP_START_URL + "my-contests/live"}
                  title="My Contests"
                >
                  My Contests
                </Link>
              </li>
              <li
                className={
                  this.state.activePage.includes("my-props") ? "active" : ""
                }
              >
                <Link
                  to={process.env.REACT_APP_START_URL + "my-props/live"}
                  title="My Props"
                >
                  My Props
                </Link>
              </li>
              <li>
                <Link
                  className="notifications"
                  to={process.env.REACT_APP_START_URL + "notifications"}
                  title="Notifications"
                >
                  <span></span>Notifications
                </Link>
              </li>
            </ul>
            <div>
              <button
                if
                className="ticket-indicator"
                onClick={this.showTicketModal}
              >
                {this.ticketHeaderDisplay()}
              </button>
              <Link
                to={process.env.REACT_APP_START_URL + "wallet"}
                className="user-wallet"
                title="Wallet"
              >
                {formatNumber(this.state.balance)}
              </Link>
              <a
                className="affiliates"
                href="https://bit.ly/ThriveAffiliateForm"
                target="_blank"
              >
                Become an Affiliate!
              </a>
              <Link
                to={process.env.REACT_APP_START_URL + "wallet"}
                className="deposit-btn"
                title="Deposit Now"
              >
                Deposit Now
              </Link>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

function formatNumber(num) {
  return "$" + (num * 1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export default withRouter(LoggedInHeader);
