import React from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import './my-contest-traditional-head-to-head.scss';
import LoggedInHeader from '../Logged-in-header/Logged-in-header';
import $ from 'jquery';

import { isAuthenticated } from "../isAuthenticated";
import Footer from "../footer/footer";
import { messages } from "../messages";

import pic from '../../assets/images/pic2.png';
import Moment from "react-moment";
import PlayerStatsPopup from "../player-stats-popup/player-stats-popup";


isAuthenticated('login', '/contest-lobby');


const requestHeadToHeadOptions = {
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
    },
};

class MyContestTraditionalHeadToHead extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view: this.props.match.params.view,
            contestId: this.props.match.params.contestId,
            userContestId: this.props.match.params.userContestId,
            opponentsId: this.props.match.params.opponentsId,
            opponentUserContestId: this.props.match.params.opponentUserContestId,
            H2Hdata: [],
            oppenentArray: [],
            picksData: [],
            pickArray: [],
            name: window.localStorage.getItem('HeadtoHeadData') ? JSON.parse(window.localStorage.getItem('HeadtoHeadData'))['name'] : false,
            rank: window.localStorage.getItem('HeadtoHeadData') ? JSON.parse(window.localStorage.getItem('HeadtoHeadData'))['rank'] : false,
            myPoints: 0,
            opponentPoints: 0,
        };

        this.fetchHeadToHeadData = this.fetchHeadToHeadData.bind(this);
        this.playerStats = React.createRef();
    }


    fetchHeadToHeadData(requestHeadToHeadOptions) {
        $('.loader').addClass('show');
        fetch(process.env.REACT_APP_SERVER_URL + 'contests/get-head-to-head-view-user-picks?contestid=' + this.state.contestId + '&opponentid=' + this.state.opponentsId + '&opponentUserContestId=' + this.state.opponentUserContestId + '&userContestId=' + this.state.userContestId, requestHeadToHeadOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    console.log(data);
                    this.parseH2HData(data);
                    this.checkForTotalMyPoints();
                    $('.loader').removeClass('show');
                } else {
                    messages('error', data['message']);
                    $('.loader').removeClass('show');
                }
            });
    }


    componentDidMount() {
        this.fetchHeadToHeadData(requestHeadToHeadOptions);
    }

    parseH2HData(data) {
        let H2Hdata = [];
        let oppenentArray = [];
        let hth = this.checkProps(data['response']['myPropPicks']);
        let opponentHth = this.checkProps(data['response']['opponentsPropPicks']);
        let pushedArray = [];
        for (let i = 0; i < hth.length; i++) {
            H2Hdata.push(hth[i]);
            H2Hdata[i].oppenent = [];
        }
        for (let j = 0; j < opponentHth.length; j++) {
            for (let k = 0; k < hth.length; k++) {
                if (opponentHth[j].propId === H2Hdata[k].propId) {
                    H2Hdata[k].oppenent.push(opponentHth[j]);
                    pushedArray.push(hth[k].propId)
                }
            }
        }
        for (let i = 0; i < opponentHth.length; i++) {
            if (pushedArray.indexOf(opponentHth[i].propId) === -1) {
                oppenentArray.push(opponentHth[i]);
            }
        }
        this.setState({ H2Hdata: H2Hdata, oppenentArray: oppenentArray });

    }

    checkProps(pickData) {
        let picksData = pickData;
        let pickArray = [];
        let primaryScore = {}, secondaryScore = {};
        picksData.forEach(data => {
            if (data.isICE && data.icePrimary) {
                primaryScore = data;
            } else if (data.isICE && !data.icePrimary) {
                secondaryScore = data;
            }
            if (!data.isICE && !data.propDisabled) {
                pickArray.push(data);
            }

        });
        this.setState({ picksData: picksData, pickArray: pickArray });
        return this.checkForDisable(primaryScore, secondaryScore);
    }

    checkForDisable(primaryScore, secondaryScore) {
        let count = 0;
        let pickArray = this.state.pickArray;
        this.state.picksData.forEach(data => {
            if (!data.isICE && data.propDisabled) {
                count++;
                if (count === 1) {
                    if (!primaryScore.propDisabled) {
                        pickArray.push(primaryScore);
                    } else {
                        if (!secondaryScore.propDisabled) {
                            pickArray.push(secondaryScore);
                            count++;
                        }
                    }
                } else if (count === 2) {
                    if (!secondaryScore.propDisabled) {
                        pickArray.push(secondaryScore);
                    }
                }

            }
        });
        return pickArray;
    }

    teamAbr(data) {
        let str = '';
        const team1Score = data['team1Score'] ? data.team1Score : 0;
        const team2Score = data['team2Score'] ? data.team2Score : 0;
        if (data.team1locationType === 'home') {
            str = data.team2Abbr + ' ' + team2Score + ' - ' + data.team1Abbr + ' ' + team1Score;
        } else if (data.team1locationType === 'away') {
            str = data.team1Abbr + ' ' + team1Score + ' - ' + data.team2Abbr + ' ' + team2Score;
        }
        return str;
    }

    numSuffix(n) {
        const suffix = ["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th";
        return n + suffix;
    }

    checkForTotalMyPoints() {
        let myPoints = 0;
        let opponentPoints = 0;

        setTimeout(function () {
            $('.my-picks span.green').each(function () {
                myPoints += ($(this).attr('rel') / 1);
            });

            $('.opponents span.green').each(function () {
                opponentPoints += ($(this).attr('rel') / 1);
            });
            this.setState({ myPoints: myPoints, opponentPoints: opponentPoints });
        }.bind(this), 1000);

    }
    triggerPlayerStatsPopup(player) {
        this.playerStats.current.openPlayerStatsPopup(player);
    }


    render() {
        return (
            <main className="my-contest-head-to-head-page traditional">
                <LoggedInHeader />
                <div className="inner">
                    <Link className="back-btn"
                        to={{
                            pathname: process.env.REACT_APP_START_URL + "my-contests/" + this.state.view + "/" + this.state.contestId + "/" + this.state.userContestId,
                            entrantsTab: true
                        }}
                        title="Back to Results">Back to Results</Link>
                    <h1>HEAD TO HEAD - with {this.state.name} (Rank {this.state.rank})</h1>
                    <div className="head-to-head-listing">
                        <div className="head">
                            <span className="player">Player</span>
                            <span className="props">Props</span>
                            <span className="live-points">Live Points</span>
                            <span className="my-picks">My Picks</span>
                            <span className="opponents">Opponent's</span>
                        </div>
                        <ul>
                            {this.state.H2Hdata.length ? this.state.H2Hdata.map(h2hd => (
                                <li key={h2hd.propId}>
                                    <div className="player" onClick={() => this.triggerPlayerStatsPopup(h2hd.player1)}>
                                        <img src={h2hd.player1.image ? h2hd.player1.image : pic} alt={h2hd.player1.firstName} />
                                        <h3>
                                            {h2hd.player1.firstName} {h2hd.player1.lastName}

                                        </h3>
                                        {
                                            h2hd.player1.leagueType === 'MLB' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && h2hd.inningEvent <= 9 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} INN | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'NFL' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && h2hd.inningEvent <= 4 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} QTR | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'NBA' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && h2hd.inningEvent <= 4 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} QTR | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'GOLF' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.numSuffix(h2hd.golfRound)} RND</span> : ''}
                                                    {!h2hd.event1Complete && h2hd.inningEvent !== 0 && h2hd.inningEvent != null ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} HOLE | {this.numSuffix(h2hd.golfRound)} RND</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.numSuffix(h2hd.golfRound)} RND</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'CRICKET' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?//nelsoncleancricket
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && /*h2hd.inningEvent <= 9 && */ !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} INN | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'CSGO2' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'LOL' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'DOTA2' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'OW' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'SOCCER' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} HALF | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'AUDL' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} HALF | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'VAL' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'UFC' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ? <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {h2hd.team1Name} vs. {h2hd.team2Name} </span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ? <span> {h2hd.team1Name} vs {h2hd.team2Name} | {this.numSuffix(h2hd.inningEvent)} Round </span> : ''}
                                                    {h2hd.event1Complete ? <span> Final |  {h2hd.team1Name} vs. {h2hd.team2Name}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                    </div>
                                    <div className="props">
                                        <h3 className="mobile">Props:</h3>
                                        <h3>
                                            <span className="prop-value">{h2hd.propValue}</span> Total<br />
                                            {h2hd.propParameters.length ? h2hd.propParameters.map(prop => (
                                                <span className="prop" key={prop.name}> ({prop.abbreviation})</span>
                                            )) : ''}
                                            {h2hd.player1.leagueType === "UFC" ? <span></span> : <span>{h2hd.player1.positionAbbreviation} | {h2hd.player1.teamAbbr}</span>}
                                        </h3>
                                    </div>
                                    <div className="live-points">
                                        <h3 className="mobile">Live Points:</h3>
                                        <span>{h2hd.livePropPoints}</span>
                                    </div>
                                    <div className="my-picks">
                                        <h3 className="mobile">My Pick:</h3>
                                        <div>
                                            {h2hd.isOver ?
                                                <span className={h2hd.livePropPoints < h2hd.propValue ? 'red' : 'green'} rel={h2hd.overPoints.toString()}>
                                                    Over = {h2hd.overPoints} PTS
                                                </span>
                                                :
                                                <span className={h2hd.livePropPoints > h2hd.propValue ? 'red' : 'green'} rel={h2hd.underPoints.toString()}>
                                                    Under = {h2hd.underPoints} PTS
                                                </span>
                                            }
                                            <div>Owned {h2hd.ownedPercentage}%</div>
                                        </div>
                                    </div>
                                    {h2hd.oppenent?.length > 0 ?
                                        <div className="opponents">
                                            <h3 className="mobile">Opponents Pick:</h3>
                                            <div>
                                                {h2hd.oppenent[0]?.isOver ?
                                                    <span className={h2hd.oppenent[0]?.livePropPoints < h2hd.oppenent[0]?.propValue ? 'red' : 'green'}
                                                        rel={h2hd.oppenent[0]?.overPoints.toString()}>
                                                        Over = {h2hd.oppenent[0]?.overPoints} PTS
                                                    </span>
                                                    :
                                                    <span className={h2hd.oppenent[0]?.livePropPoints > h2hd.oppenent[0]?.propValue ? 'red' : 'green'}
                                                        rel={h2hd.oppenent[0]?.underPoints.toString()}>
                                                        Under = {h2hd.oppenent[0]?.underPoints} PTS
                                                    </span>
                                                }
                                                <div>Owned {h2hd.oppenent[0]?.ownedPercentage}%</div>
                                            </div>
                                        </div>
                                        : ''}

                                </li>
                            )) : ''}
                            {this.state.oppenentArray.length ? this.state.oppenentArray.map(h2hd => (
                                <li key={h2hd.propId}>
                                    <div className="player">
                                        <img src={h2hd.player1.image ? h2hd.player1.image : pic} alt={h2hd.player1.firstName} />
                                        <h3>
                                            {h2hd.player1.firstName} {h2hd.player1.lastName}
                                        </h3>
                                        {
                                            h2hd.player1.leagueType === 'MLB' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && h2hd.inningEvent <= 9 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} INN | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'NFL' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && h2hd.inningEvent <= 4 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} QTR | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'NBA' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && h2hd.inningEvent <= 4 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} QTR | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'GOLF' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.numSuffix(h2hd.golfRound)} RND</span> : ''}
                                                    {!h2hd.event1Complete && h2hd.inningEvent !== 0 && h2hd.inningEvent != null ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} HOLE | {this.numSuffix(h2hd.golfRound)} RND</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.numSuffix(h2hd.golfRound)} RND</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'CRICKET' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?//nelsoncleancricket
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && /*h2hd.inningEvent <= 9 && */ !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} INN | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'CSGO2' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'LOL' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'DOTA2' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'OW' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'SOCCER' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} HALF | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'AUDL' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} HALF | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'VAL' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ?
                                                        <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ?
                                                        <span>{this.numSuffix(h2hd.inningEvent)} RND | {this.teamAbr(h2hd)}</span> : ''}
                                                    {h2hd.event1Complete ? <span> Final | {this.teamAbr(h2hd)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            h2hd.player1.leagueType === 'UFC' ?
                                                <span>
                                                    {h2hd.inningEvent === null || h2hd.inningEvent === 0 ? <span><Moment utc local format="hh:mm a">{h2hd.startTimeEvent1}</Moment> | {h2hd.team1Name} vs. {h2hd.team2Name} </span> : ''}
                                                    {h2hd.inningEvent >= 1 && !h2hd.event1Complete ? <span> {h2hd.team1Name} vs {h2hd.team2Name} | {this.numSuffix(h2hd.inningEvent)} Round </span> : ''}
                                                    {h2hd.event1Complete ? <span> Final |  {h2hd.team1Name} vs. {h2hd.team2Name}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                    </div>
                                    <div className="props">
                                        <h3 className="mobile">Props:</h3>
                                        <h3>
                                            <span className="prop-value">{h2hd.propValue}</span> Total<br />
                                            {h2hd.propParameters.length ? h2hd.propParameters.map(prop => (
                                                <span className="prop" key={prop.name}> ({prop.abbreviation})</span>
                                            )) : ''}
                                            {h2hd.player1.leagueType === "UFC" ? <span></span> : <span>{h2hd.player1.positionAbbreviation} | {h2hd.player1.teamAbbr}</span>}

                                        </h3>
                                    </div>
                                    <div className="live-points">
                                        <h3 className="mobile">Live Points:</h3>
                                        <span>{h2hd.livePropPoints}</span>
                                    </div>
                                    <div className="my-picks">
                                        <span>&nbsp;</span>
                                    </div>
                                    <div className="opponents">
                                        <h3 className="mobile">Opponents Pick:</h3>
                                        <div>
                                            {h2hd.isOver ?
                                                <span className={h2hd.livePropPoints < h2hd.propValue ? 'red' : 'green'} rel={h2hd.overPoints.toString()}>
                                                    Over = {h2hd.overPoints} PTS
                                                </span>
                                                :
                                                <span className={h2hd.livePropPoints > h2hd.propValue ? 'red' : 'green'} rel={h2hd.underPoints.toString()}>
                                                    Under = {h2hd.underPoints} PTS
                                                </span>
                                            }
                                            <div>Owned {h2hd.ownedPercentage}%</div>
                                        </div>
                                    </div>

                                </li>
                            )) : ''}
                        </ul>
                    </div>
                    <div className="totals">
                        <div>Earned points:</div>
                        <div>{this.state.myPoints}</div>
                        <div>{this.state.opponentPoints}</div>
                    </div>
                </div>
                <PlayerStatsPopup ref={this.playerStats} />
                <Footer />
            </main>
        );
    }
}


export default withRouter(MyContestTraditionalHeadToHead);
