import React from 'react';
import { withRouter } from "react-router";
import './my-contest-traditional-picks-live.scss';
import $ from 'jquery';
import Moment from 'react-moment';
import PlayerStatsPopup from "../player-stats-popup/player-stats-popup";
const defaultPlayerPic = require('../../assets/images/defaultPic.png');


const getPicksOptions = {
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Token': window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData'))['accessToken'] : false
    }
};

class MyContestTraditionalPicksLive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            picks: [],
            pickArray: [],
            picksData: [],
            contestId: this.props.match.params.contestId,
            userContestId: this.props.match.params.userContestId,

        };
        this.fetchNewPicksData = this.fetchNewPicksData.bind(this);
        this.checkProps = this.checkProps.bind(this);
        this.checkForDisable = this.checkForDisable.bind(this);
        this.playerStats = React.createRef();
    }


    /*get picks and format array*/
    fetchNewPicksData(getPicksOptions) {
        $('.loader').addClass('show');

        fetch(process.env.REACT_APP_SERVER_URL + 'contests/get-user-picks-live-contest?contestid=' + this.state.contestId + '&userContestId=' + this.state.userContestId, getPicksOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data['success']) {
                    let picksData = [];
                    data['response'].forEach((liveData) => {
                        picksData.push(liveData);
                    });
                    this.checkProps(picksData);

                    $('.loader').removeClass('show');
                } else {
                    $('.loader').removeClass('show');
                }
            });

    }


    componentDidMount() {
        this.fetchNewPicksData(getPicksOptions)

    }

    checkProps(pickData) {
        let picksData = pickData;
        let pickArray = [];
        let primaryScore = {}, secondaryScore = {};
        picksData.forEach(data => {
            if (data.isICE && data.icePrimary) {
                primaryScore = data;
            } else if (data.isICE && !data.icePrimary) {
                secondaryScore = data;
            }
            if (!data.isICE && !data.propDisabled) {
                pickArray.push(data);
            }

        });
        this.setState({ pickArray: pickArray, picksData: picksData });
        this.checkForDisable(primaryScore, secondaryScore);
    }

    checkForDisable(primaryScore, secondaryScore) {
        let count = 0;
        let picksData = this.state.picksData;
        let pickArray = this.state.pickArray;
        picksData.forEach(data => {
            if (!data.isICE && data.propDisabled) {
                count++;
                if (count === 1) {
                    if (!primaryScore.propDisabled) {
                        pickArray.push(primaryScore);
                    } else {
                        if (!secondaryScore.propDisabled) {
                            pickArray.push(secondaryScore);
                            count++;
                        }
                    }
                } else if (count === 2) {
                    if (!secondaryScore.propDisabled) {
                        pickArray.push(secondaryScore);
                    }
                }

            }
        });
        this.setState({ pickArray: pickArray, picksData: picksData });
    }

    teamAbr(data) {
        let str = '';
        const team1Score = data['team1Score'] ? data.team1Score : 0;
        const team2Score = data['team2Score'] ? data.team2Score : 0;
        if (data.team1locationType === 'home') {
            str = data.team2Abbr + ' ' + team2Score + ' - ' + data.team1Abbr + ' ' + team1Score;
        } else if (data.team1locationType === 'away') {
            str = data.team1Abbr + ' ' + team1Score + ' - ' + data.team2Abbr + ' ' + team2Score;
        }
        return str;
    }

    numSuffix(n) {
        const suffix = ["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th";
        return n + suffix;
    }

    triggerPlayerStatsPopup(player) {
        this.playerStats.current.openPlayerStatsPopup(player);
    }

    isPrime(num) {
        for (let i = 2; i < num; i++)
            if (num % i === 0) return false;
        return num > 1;
    }

    imageExists(source) {
        let retVal = false;
        const img = new Image();
        img.src = source;

        if (img.complete) {
            retVal = true;
        } else {
            img.onload = () => {
                retVal = true;
            };

            img.onerror = () => {
                retVal = false;
            };
        }

        return retVal;

    }


    render() {
        return (
            <div className="tab-container my-contest active" rel="making-picks">
                <div className="making-picks-listing">
                    <div className="head">
                        <span>Player</span>
                        <span>Props</span>
                        <span>Live Points</span>
                        <span>Points</span>
                    </div>
                    <ul className="listing">
                        {this.state.pickArray.length ? this.state.pickArray.map(pick => (
                            <li key={pick.propId}>
                                <div className="player" onClick={() => this.triggerPlayerStatsPopup(pick.player1)}>
                                    {/*{ pick.player1.leagueType === 'HOCKEY' ? (this.imageExists(pick.player1.image) ? <img src={pick.player1.image} alt={pick.player1.firstName}/> : <img src={defaultPlayerPic} alt={pick.player1.firstName}/>) : <img src={pick.player1.image} alt={pick.player1.firstName}/>}*/}
                                    <img src={pick.player1.image} alt={pick.player1.firstName}/>
                                    <h3>
                                        {pick.player1.firstName} {pick.player1.lastName}
                                        {
                                            pick.player1.leagueType === 'MLB' ?
                                                <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ? <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.inningEvent >= 1 && pick.inningEvent <= 9 && !pick.event1Complete ? <span>{this.numSuffix(pick.inningEvent)} INN | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.event1Complete ? <span> Final | {this.teamAbr(pick)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            pick.player1.leagueType === 'NFL' ?
                                                <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ? <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.inningEvent >= 1 && pick.inningEvent <= 4 && !pick.event1Complete ? <span>{this.numSuffix(pick.inningEvent)} QTR | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.event1Complete ? <span> Final | {this.teamAbr(pick)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            pick.player1.leagueType === 'NBA' ?
                                                <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ? <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.inningEvent >= 1 && pick.inningEvent <= 4 && !pick.event1Complete ? <span>{this.numSuffix(pick.inningEvent)} QTR | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.event1Complete ? <span> Final | {this.teamAbr(pick)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            pick.player1.leagueType === 'GOLF' ?
                                                <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ? <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.numSuffix(pick.golfRound)} RND</span> : ''}
                                                    {!pick.event1Complete && pick.inningEvent !== 0 && pick.inningEvent != null ? <span>{this.numSuffix(pick.inningEvent)} HOLE | {this.numSuffix(pick.golfRound)} RND</span> : ''}
                                                    {pick.event1Complete ? <span> Final | {this.numSuffix(pick.golfRound)} RND</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            pick.player1.leagueType === 'CRICKET' ?//nelsoncleancricket
                                                <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ? <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.inningEvent >= 1 && /*pick.inningEvent<=9 && */ !pick.event1Complete ? <span>{this.numSuffix(pick.inningEvent)} INN | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.event1Complete ? <span> Final | {this.teamAbr(pick)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            pick.player1.leagueType === 'CSGO2' || pick.player1.leagueType === 'SLG_CSGO' ?
                                                <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ? <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.inningEvent >= 1 && !pick.event1Complete ? <span>{this.numSuffix(pick.inningEvent)} RND | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.event1Complete ? <span> Final | {this.teamAbr(pick)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            pick.player1.leagueType === 'LOL' ?
                                                <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ? <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.inningEvent >= 1 && !pick.event1Complete ? <span>{this.numSuffix(pick.inningEvent)} RND | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.event1Complete ? <span> Final | {this.teamAbr(pick)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            pick.player1.leagueType === 'DOTA2' ?
                                                <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ? <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.inningEvent >= 1 && !pick.event1Complete ? <span>{this.numSuffix(pick.inningEvent)} RND | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.event1Complete ? <span> Final | {this.teamAbr(pick)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            pick.player1.leagueType === 'OW' ?
                                                <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ? <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.inningEvent >= 1 && !pick.event1Complete ? <span>{this.numSuffix(pick.inningEvent)} RND | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.event1Complete ? <span> Final | {this.teamAbr(pick)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            pick.player1.leagueType === 'SOCCER' ?
                                                <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ? <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.inningEvent >= 1 && !pick.event1Complete ? <span>{this.numSuffix(pick.inningEvent)} HALF | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.event1Complete ? <span> Final | {this.teamAbr(pick)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            pick.player1.leagueType === 'AUDL' ?
                                                <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ? <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.inningEvent >= 1 && !pick.event1Complete ? <span>{this.numSuffix(pick.inningEvent)} HALF | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.event1Complete ? <span> Final | {this.teamAbr(pick)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            pick.player1.leagueType === 'VAL' ?
                                                <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ? <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.inningEvent >= 1 && !pick.event1Complete ? <span>{this.numSuffix(pick.inningEvent)} RND | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.event1Complete ? <span> Final | {this.teamAbr(pick)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            pick.player1.leagueType === 'HOCKEY' ?
                                                <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ? <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.inningEvent >= 1 && !pick.event1Complete ? <span>{this.numSuffix(pick.inningEvent)} PER | {this.teamAbr(pick)}</span> : ''}
                                                    {pick.event1Complete ? <span> Final | {this.teamAbr(pick)}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                        {
                                            pick.player1.leagueType === 'UFC' ?
                                                <span>
                                                    {pick.inningEvent === null || pick.inningEvent === 0 ? <span><Moment utc local format="hh:mm a">{pick.startTimeEvent1}</Moment> | {pick.team1Name} vs. {pick.team2Name} </span> : ''}
                                                    {pick.inningEvent >= 1 && !pick.event1Complete ? <span> {pick.team1Name} vs {pick.team2Name} | {this.numSuffix(pick.inningEvent)} Round </span> : ''}
                                                    {pick.event1Complete ? <span> Final |  {pick.team1Name} vs. {pick.team2Name}</span> : ''}
                                                </span>
                                                : ''
                                        }
                                    </h3>
                                </div>
                                <div className="props">
                                    <h3>
                                        <span className="prop-value">{pick.propValue}</span> Total<br />
                                        {pick.propParameters.length ?
                                            <span>({pick.propParameters.map((prop, index) => (<span className="prop" key={prop.name}>{index > 0 ? ' + ' : ''}{prop.abbreviation}</span>))})</span>
                                            : ''}
                                    </h3>
                                </div>
                                <div className="live-points">
                                    <h3 className="mobile">Live Points:</h3>
                                    <span>
                                        {pick.livePropPoints}
                                    </span>
                                </div>
                                <div className="points">
                                    <div>
                                        {
                                            pick.propParameters[0]['abbreviation'] === 'VAL' ?
                                                pick.over ?
                                                    <span
                                                        className={pick.livePropPoints < pick.propValue ? (pick.event1Complete ? 'red' : 'blue') : 'green'}>
                                                Over = {pick.overPoints} PTS
                                            </span>
                                                    :
                                                    <span
                                                        className={pick.livePropPoints > pick.propValue ? (pick.event1Complete ? 'red' : 'blue') : 'green'}>
                                                Under = {pick.underPoints} PTS
                                            </span>
                                                : ''
                                        }
                                        {
                                            pick.propParameters[0]['abbreviation'] === 'P' ?
                                                pick.over ?
                                                    <span
                                                        className={!this.isPrime(pick.livePropPoints) ? (pick.event1Complete ? 'red' : 'blue') : 'green'}>
                                                Prime = {pick.overPoints} PTS
                                            </span>
                                                    :
                                                    <span
                                                        className={this.isPrime(pick.livePropPoints) ? (pick.event1Complete ? 'red' : 'blue') : 'green'}>
                                                Not Prime = {pick.underPoints} PTS
                                            </span>
                                                : ''
                                        }
                                        {
                                            pick.propParameters[0]['abbreviation'] === 'OOE' ?
                                                pick.over ?
                                                    // <span className={pick.livePropPoints < pick.propValue ? (pick.event1Complete ? 'red' : 'blue') : 'green'}>
                                                    <span
                                                        className={pick.livePropPoints % 2 !== 0 ? (pick.event1Complete ? 'red' : 'blue') : 'green'}>
                                                Even = {pick.overPoints} PTS
                                            </span>
                                                    :
                                                    <span
                                                        className={pick.livePropPoints % 2 === 0 ? (pick.event1Complete ? 'red' : 'blue') : 'green'}>
                                                Odd = {pick.underPoints} PTS
                                            </span>
                                                : ''
                                        }
                                        {
                                            pick.propParameters[0]['abbreviation'] === 'IOO' ?
                                                pick.over ?
                                                    // <span className={pick.livePropPoints < pick.propValue ? (pick.event1Complete ? 'red' : 'blue') : 'green'}>

                                                    <span
                                                        className={(pick.livePropPoints > pick.lowerRange && pick.livePropPoints < pick.upperRange) ? (pick.event1Complete ? 'red' : 'blue') : 'green'}>
                                                In Range = {pick.overPoints} PTS
                                            </span>
                                                    :
                                                    <span
                                                        className={(pick.livePropPoints < pick.lowerRange || pick.livePropPoints > pick.upperRange) ? (pick.event1Complete ? 'red' : 'blue') : 'green'}>
                                                Out of Range = {pick.underPoints} PTS
                                            </span>
                                                : ''
                                        }
                                        {
                                            pick.player1.leagueType !== 'LOTTERY' ?
                                                pick.over ?
                                                    <span
                                                        className={pick.livePropPoints < pick.propValue ? (pick.event1Complete ? 'red' : 'blue') : 'green'}>
                                                    Over = {pick.overPoints} PTS
                                                </span>
                                                    :
                                                    <span
                                                        className={pick.livePropPoints > pick.propValue ? (pick.event1Complete ? 'red' : 'blue') : 'green'}>
                                                    Under = {pick.underPoints} PTS
                                                </span> : ''
                                        }

                                        <div>Owned {pick.ownedPercentage}%</div>
                                    </div>
                                </div>
                            </li>
                        )) : ''}
                    </ul>
                </div>
                <PlayerStatsPopup ref={this.playerStats} />
            </div>
        );
    }
}


export default withRouter(MyContestTraditionalPicksLive);
