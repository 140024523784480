import mixpanel from "mixpanel-browser";

let envCheck = process.env.REACT_APP_SERVER_URL === "https://api.thrivefantasy.com/";

envCheck = true;

let actions = {
    identify: (id) => {
        if (envCheck) mixpanel.identify(id);
    },
    alias: (id) => {
        if (envCheck) mixpanel.alias(id);
    },
    track: (name, props) => {
        if (envCheck) mixpanel.track(name, props);
    },
    reset: () => {
        if (envCheck) mixpanel.reset();
    },
    people: {
        set: (props) => {
            if (envCheck) mixpanel.people.set(props);
        },
    },
};

export let Mixpanel = actions;