import React from "react";
import { withRouter } from "react-router";
import "./contest-entrants.scss";
import $ from "jquery";
import { Mixpanel } from "../mixpanel/Mixpanel";

let ContestEntrantsBody = {
  contestId: 0,
  currentPage: 1,
  currentSize: 1000,
  isFollowing: false,
};

const ContestEntrantsOptions = {
  method: "post",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Token: window.localStorage.getItem("userData")
      ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
      : false,
  },
  body: JSON.stringify(ContestEntrantsBody),
};

class ContestEntrants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entrants: [],
      userId: window.localStorage.getItem("userData")
        ? JSON.parse(window.localStorage.getItem("userData"))["userDto"][
            "userId"
          ]
        : "",
    };

    this.fetchContestEntrantsData = this.fetchContestEntrantsData.bind(this);
    this.changeFollowStatus = this.changeFollowStatus.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  fetchContestEntrantsData(ContestEntrantsOptions) {
    $(".loader").addClass("show");
    fetch(
      process.env.REACT_APP_SERVER_URL + "userContests/entrantsListByContest",
      ContestEntrantsOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["success"]) {
          this.setState({ entrants: data["response"]["data"] });
          $(".loader").removeClass("show");
        }
      });
  }

  handleFilter(event) {
    ContestEntrantsBody["isFollowing"] = event.target.getAttribute("rel");
    ContestEntrantsOptions["body"] = JSON.stringify(ContestEntrantsBody);
    $(".actions").find("button").removeClass("active");
    event.target.classList.add("active");
    this.fetchContestEntrantsData(ContestEntrantsOptions);

    Mixpanel.track("web:user_filtered_contest_entrants", {
      "Filter By": event.target.getAttribute("rel"),
    });
  }

  componentDidMount() {
    ContestEntrantsBody["contestId"] = this.props.match.params.contestId;
    ContestEntrantsOptions["body"] = JSON.stringify(ContestEntrantsBody);
    this.fetchContestEntrantsData(ContestEntrantsOptions);
  }

  changeFollowStatus(entrant) {
    const following = entrant.following ? false : true;
    const dataFollow = {
      userId: entrant.userId,
      isFollowing: following,
    };

    const followOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Token: window.localStorage.getItem("userData")
          ? JSON.parse(window.localStorage.getItem("userData"))["accessToken"]
          : false,
      },
      body: JSON.stringify(dataFollow),
    };

    fetch(
      process.env.REACT_APP_SERVER_URL + "userContests/userFollowsOtherUser",
      followOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["success"]) {
          ContestEntrantsBody["contestId"] = this.props.match.params.contestId;
          ContestEntrantsOptions["body"] = JSON.stringify(ContestEntrantsBody);
          this.fetchContestEntrantsData(ContestEntrantsOptions);
        }
      });

    Mixpanel.track("web:user_changed_follow_status", {
      "User Id": entrant.userId,
      Following: following,
    });
  }

  render() {
    return (
      <div className="tab-container entrants" rel="entrants">
        <div className="actions">
          <button
            onClick={this.handleFilter}
            className="active"
            title="All"
            rel="false"
          >
            All
          </button>
          <button onClick={this.handleFilter} title="Following" rel="true">
            Following
          </button>
        </div>
        <div className="contest-entrants-listing">
          <div className="head">
            <span>Username</span>
            <span>Entries</span>
            <span>Follow/Following</span>
          </div>
          <ul className="listing">
            {this.state.entrants.length ? (
              this.state.entrants.map((entrant) => (
                <li key={entrant.userContestId}>
                  <div className="entrant">
                    <h3>{entrant.userName}</h3>
                  </div>
                  <div className="entries">
                    <div className="mobile">Entries: </div>
                    {entrant.noOfEntries > 1 && entrant.noOfEntries < 10 ? (
                      <span>M - 0{entrant.noOfEntries}</span>
                    ) : (
                      ""
                    )}
                    {entrant.noOfEntries === 1 ? (
                      <span>{entrant.noOfEntries}</span>
                    ) : (
                      ""
                    )}
                    {entrant.noOfEntries >= 10 ? (
                      <span>M - {entrant.noOfEntries}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="following">
                    {entrant.userId !== this.state.userId ? (
                      <button
                        onClick={() => this.changeFollowStatus(entrant)}
                        className={entrant.following ? "active" : ""}
                      >
                        {entrant.userId !== this.state.userId
                          ? entrant.following
                            ? "Following"
                            : "Follow"
                          : ""}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </li>
              ))
            ) : (
              <li></li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(ContestEntrants);
